export const loadingText = [
  "goood vibes coming your way 🏖",
  "stay strong my friend 💪",
  "collect beautiful memories 🌸",
  "don't doubt your vibe 🤙",
  "remember, time is precious ⌛️",
  "protect your dream 🔥",
  "just go for it 🏃‍♀️🏃‍♂️",
  "reeeeeeeeeee",
  "looking good champ 🏆",
  "cool music ♪ playing",
  "lets goooooo! 🚀",
  "celebrate our differences 🦹🏼‍♀️👨🏿‍🔬👩🏽‍💻",
  "are you.. defecating now?",
  "cheer up! 🙆🏻‍♂️",
  "you're certified awesome 😉",
  "brrrrrrrrrr",
  "beep boop beep boop 🤖",
  "count your blessings ✨",
  "let's go change the world! 🚀",
  "pause, breathe, smile 🍵",
  "Cows have best friends! 🐮🐮",
  "Sea otters hold hands when they sleep so they don't drift apart 🤝",
  "Some turtles can breathe through their butts 🐢💨",
  "Gorillas hum when they eat their favorite food ♪",
  "The Netherlands sends Canada 20,000 tulip bulbs as an appreciation for their help during WWII 🌷",
  "Dolphins have names for each other 🐬🐬",
  "Crows visit their parents after they've left the nest 🏡",
  "Sometimes when they're out gathering, bees will fall asleep in flowers 🌻🐝",
  "Dogs understand laughter 🐶",
  "Blind people smile even though they have never seen someone smile before 😎",
  "When Swans find love, they tend to bond for life 🦢❤️🦢",
  "Studies show that cats love people more than food 😻",
  "Otters have pet rocks that they carry around with them 🥺",
  "Gentoo penguins use pebble to propose to their life mates 🐧",
];
