import { NgModule } from "@angular/core";
import {
  PreloadAllModules,
  NoPreloading,
  RouterModule,
  Routes,
} from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { AutoLoginGuard } from "./guards/auto-login.guard";
import { TutorialGuard } from "./guards/tutorial.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule),
    // canLoad: [AuthGuard]
    // canActivate: [AuthGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: "tutorial",
    loadChildren: () =>
      import("./pages/tutorial/tutorial.module").then(
        (m) => m.TutorialPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
    // canLoad: [TutorialGuard]
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./pages/settings/settings.module").then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: "chat",
    loadChildren: () =>
      import("./pages/chat/chat.module").then((m) => m.ChatPageModule),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./pages/signup/signup.module").then((m) => m.SignupPageModule),
  },
  {
    path: "sign-up-verification",
    loadChildren: () =>
      import("./pages/sign-up-verification/sign-up-verification.module").then(
        (m) => m.SignUpVerificationPageModule
      ),
  },
  {
    path: "chat-confirmation",
    loadChildren: () =>
      import("./pages/chat-confirmation/chat-confirmation.module").then(
        (m) => m.ChatConfirmationPageModule
      ),
  },
  {
    path: "chat-signup",
    loadChildren: () =>
      import("./pages/chat-signup/chat-signup.module").then(
        (m) => m.ChatSignupPageModule
      ),
  },
  {
    path: "testchat",
    loadChildren: () =>
      import("./pages/testchat/testchat.module").then(
        (m) => m.TestchatPageModule
      ),
  },
  {
    path: "chat-temp",
    loadChildren: () =>
      import("./pages/chat-temp/chat-temp.module").then(
        (m) => m.ChatTempPageModule
      ),
  },
  {
    path: "admin-control",
    loadChildren: () =>
      import("./pages/admin-control/admin-control.module").then(
        (m) => m.AdminControlPageModule
      ),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'mobile',
    loadChildren: () => import('./pages/mobile/mobile.module').then( m => m.MobilePageModule)
  },
  {
    path: 'mobile-about',
    loadChildren: () => import('./pages/mobile-about/mobile-about.module').then( m => m.MobileAboutPageModule)
  },
  {
    path: 'download',
    loadChildren: () => import('./pages/download/download.module').then( m => m.DownloadPageModule)
  },
  {
    path: 'app-review',
    loadChildren: () => import('./pages/app-review/app-review.module').then( m => m.AppReviewPageModule)
  },
  // {
  //   path: '',
  //   redirectTo: '/tabs',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'item-detail',
  //   loadChildren: () => import('./pages/item-detail/item-detail.module').then( m => m.ItemDetailPageModule)
  // },
  // {
  //   path: 'mbti-test',
  //   loadChildren: () => import('./pages/mbtipages/mbti-test/mbti-test.module').then( m => m.MbtiTestPageModule)
  // },
  // {
  //   path: 'mbti-test-result',
  //   loadChildren: () => import('./pages/mbtipages/mbti-test-result/mbti-test-result.module').then( m => m.MbtiTestResultPageModule)
  // },
  // {
  //   path: 'intj',
  //   loadChildren: () => import('./pages/mbtipages/intj/intj.module').then( m => m.INTJPageModule)
  // },
  // {
  //   path: 'entp',
  //   loadChildren: () => import('./pages/mbtipages/entp/entp.module').then( m => m.ENTPPageModule)
  // },
  // {
  //   path: 'entj',
  //   loadChildren: () => import('./pages/mbtipages/entj/entj.module').then( m => m.ENTJPageModule)
  // },
  // {
  //   path: 'enfp',
  //   loadChildren: () => import('./pages/mbtipages/enfp/enfp.module').then( m => m.enfpPageModule)
  // },
  // {
  //   path: 'enfj',
  //   loadChildren: () => import('./pages/mbtipages/enfj/enfj.module').then( m => m.enfjPageModule)
  // },
  // {
  //   path: 'estp',
  //   loadChildren: () => import('./pages/mbtipages/estp/estp.module').then( m => m.EstpPageModule)
  // },
  // {
  //   path: 'estj',
  //   loadChildren: () => import('./pages/mbtipages/estj/estj.module').then( m => m.EstjPageModule)
  // },
  // {
  //   path: 'esfp',
  //   loadChildren: () => import('./pages/mbtipages/esfp/esfp.module').then( m => m.EsfpPageModule)
  // },
  // {
  //   path: 'esfj',
  //   loadChildren: () => import('./pages/mbtipages/esfj/esfj.module').then( m => m.EsfjPageModule)
  // },
  // {
  //   path: 'intp',
  //   loadChildren: () => import('./pages/mbtipages/intp/intp.module').then( m => m.IntpPageModule)
  // },
  // {
  //   path: 'infp',
  //   loadChildren: () => import('./pages/mbtipages/infp/infp.module').then( m => m.InfpPageModule)
  // },
  // {
  //   path: 'infj',
  //   loadChildren: () => import('./pages/mbtipages/infj/infj.module').then( m => m.InfjPageModule)
  // },
  // {
  //   path: 'isfp',
  //   loadChildren: () => import('./pages/mbtipages/isfp/isfp.module').then( m => m.IsfpPageModule)
  // },
  // {
  //   path: 'isfj',
  //   loadChildren: () => import('./pages/mbtipages/isfj/isfj.module').then( m => m.IsfjPageModule)
  // },
  // {
  //   path: 'istp',
  //   loadChildren: () => import('./pages/mbtipages/istp/istp.module').then( m => m.IstpPageModule)
  // },
  // {
  //   path: 'istj',
  //   loadChildren: () => import('./pages/mbtipages/istj/istj.module').then( m => m.IstjPageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    // RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
