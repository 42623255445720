import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private storage: Storage) {}

  // set a key/value
  async setData(key: string, data: any): Promise<any> {
    try {
      const result = await this.storage.set(key, data);
      //console.log("data: " + JSON.stringify(result))
      return true;
    } catch (reason) {
      console.log("reason: " + reason);
      return false;
    }
  }

  // to get a key/value pair
  async getData(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }

  // remove a single key value:
  removeData(key: string) {
    this.storage.remove(key);
  }

  // delete all data from your application:
  clearData() {
    this.storage.clear();
  }

  // For OBJECT SPECIFIC

  // set a key/value object
  async setObject(key: string, object: Object) {
    try {
      const result = await this.storage.set(key, JSON.stringify(object));
      return true;
    } catch (reason) {
      console.log(reason);
      return false;
    }
  }

  // get a key/value object
  async getObject(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return JSON.parse(result);
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }
}
