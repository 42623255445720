export const appText = {
  TAB1TITLE: "Më",
  TAB1TEXT: "Home",
  TAB2TITLE: "Library",
  TAB2TEXT: "Library",
  TAB2TITLE1: "INTRO",
  TAB2TITLE1P1:
    "Carl Jung (1875-1961) was Swiss psychiatrist who proposed a theory of psychological types. His theory was taken and extended by Katharine Briggs and her daughter, Isabel Myers, personality enthusiasts who had studied his work extensively. They developed the Myers-Briggs Type Indicator which classified people into 16 different types on the basis of four dichotomies: Introversion-Extroversion, Sensing-Intuition, Thinking-Feeling, and Judging-Perceiving. The first three were adapted from Jung and the last developed by Myers-Briggs. So, for example, a person could be Extroverted-Sensing-Feeling-Perceiving (ESFP) or Introverted-iNtuiting-Thinking-Judging (INTJ). The Myers-Briggs types are the most popular pop-psych system. The Open Extended Jungian Type Scales was developed as an open source alternative to the Myers-Briggs Type Indicator.",
  TAB2TITLE2: "THE 4 LETTERS",
  TAB2TITLE2P1:
    "Introversion (I) vs. Extroversion (E); sometimes described as a persons orientation, either oriented inside themselves or to the outside world. Other times the focus is put more explicitly on social interaction, with some claiming that social interactions wears out introverts whereas social interaction raises the energy level in extroverts.",
  TAB2TITLE2P2:
    "Sensing (S) vs. Intuition (N); defined as how a person takes in information by Myers-Briggs, who said that sensors pay attention to the five senses while intuitives pay attention to possibilities.",
  TAB2TITLE2P3:
    "Feeling (F) vs. Thinking (T); has been defined as what a person values and what they base their decisions off of: either interpersonal considerations or through dispassionate logic.",
  TAB2TITLE2P4:
    "Judging (J) vs. Perceiving (P); was a dichotomy added by Myers and Briggs to pick between the second and third pair of functions. Individuals who prefer a structured lifestyle are supposed to use their judging functions (thinking and feeling) while individuals who prefer a flexible lifestyle are supposed to prefer the sensing functions (sensing and intuition).",
  TAB2TITLE1P5: "",

  BACK_BUTTON_TEXT: "Back",
  TUTORIAL_SKIP_BUTTON: "Skip",
  TUTORIAL_SLIDE1_TITLE: "Welcome!",
  TUTORIAL_SLIDE1_DESCRIPTION:
    "Ever wonder what colors are unique to you? <b><mark>Më</mark></b> is your own personality hub with the mission to help you find your true colors.",
  TUTORIAL_SLIDE2_TITLE: "Know your personality type?",
  TUTORIAL_SLIDE2_DESCRIPTION:
    "<mark>Select</mark> your personality type and we can get you set up. Let's get you started right away!",
  TUTORIAL_SLIDE4_TITLE: "Jump Start!",
  TUTORIAL_SLIDE4_DESCRIPTION:
    "Need help? Check out the Super Starter README for a full tutorial",
  TUTORIAL_SLIDE5_TITLE: "Discover your color",

  SETTINGS_TITLE: "Settings",
  SETTINGS_OPTION1: "Push Notification",
  SETTINGS_OPTION2: "Display Name",
  SETTINGS_OPTION3: "Personality",
  SETTINGS_OPTION4: "Option 4",
  SETTINGS_OPTION5: "Color",
  SETTINGS_OPTION6: "Private Mode",

  SETTINGS_PROFILE_BUTTON: "Edit Profile",
  SETTINGS_PAGE_PROFILE: "Edit Profile",
  WELCOME_TITLE: "Welcome",
  LOGIN_TITLE: "Sign in",
  LOGIN_ERROR:
    "Unable to sign in. Please check your account information and try again.",
  LOGIN_BUTTON: "Sign in",
  SIGNUP_TITLE: "Sign up",
  SIGNUP_ERROR:
    "Unable to create account. Please check your account information and try again.",
  SIGNUP_BUTTON: "Sign up",

  DONE_BUTTON: "Done",
  CANCEL_BUTTON: "Cancel",
  DELETE_BUTTON: "Delete",

  ENTP_TITLE1: "INTRO",
  ENTP_TITLE1P1:
    "Using their primary function-attitude of extraverted intuition (Ne), ENTPs are quick to see complex interrelationships between people, things, and ideas. These interrelationships are analyzed in profound detail through the ENTPs auxiliary function, introverted thinking (Ti). The result is an in-depth understanding of the way things and relationships work, and how they can be improved. To the ENTP, competence and intelligence are particularly prized, both in themselves and in other people.",
  ENTP_TITLE1P2:
    "ENTPs are frequently described as clever, cerebrally and verbally quick, enthusiastic, outgoing, innovative, flexible, loyal and resourceful. ENTPs are motivated by a desire to understand and improve the world they live in. They are usually accurate in sizing up a situation. They may have a perverse sense of humor and sometimes play devil's advocate, which can create misunderstandings with friends, coworkers, and family. ENTPs are ingenious and adept at directing relationships between means and ends. ENTPs devise fresh, unexpected solutions to difficult problems. However, they are less interested in generating and following through with detailed plans than in generating ideas and possibilities. In a team environment, ENTPs are most effective in a role where they can draw on their abilities to offer deep understanding, a high degree of flexibility, and innovative solutions to problems. The ENTP regards a comment like \"it can't be done\" as a personal challenge, and, if properly motivated, will spare no effort to discover a solution.",

  ENTJ_TITLE1: "INTRO",
  ENTJ_TITLE1P1:
    "ENTJs focus on the most efficient and organized means of performing a task. This quality, along with their goal orientation, often makes ENTJs superior leaders, both realistic and visionary in implementing a long-term plan. ENTJs tend to be fiercely independent in their decision making, having a strong will that insulates them against external influence. Generally highly competent, ENTJs analyze and structure the world around them in a logical and rational way. Due to this straightforward way of thinking, ENTJs tend to have the greatest difficulty of all the types in applying subjective considerations and emotional values into the decision-making process.",
  ENTJ_TITLE1P2:
    "ENTJs often excel in business and other areas that require systems analysis, original thinking, and an economically savvy mind. They are dynamic and pragmatic problem solvers. They tend to have a high degree of confidence in their own abilities, making them assertive and outspoken. In their dealings with others, they are generally outgoing, charismatic, fair-minded, and unaffected by conflict or criticism. However, these qualities can make ENTJs appear arrogant, insensitive, and confrontational. They can overwhelm others with their energy and desire to order the world according to their own vision. As a result, they may seem intimidating, hasty, and controlling.",
  ENTJ_TITLE1P3:
    "ENTJs tend to cultivate their personal power. They often end up taking charge of a situation that seems (to their mind, at least) to be out of control, or that can otherwise be improved upon and strengthened. They strive to learn new things, which helps them become resourceful problem-solvers. However, since ENTJs rely on provable facts, they may find subjective issues pointless. ENTJs appear to take a tough approach to emotional or personal issues, and so can be viewed as aloof and insensitive. In situations requiring feeling and value judgments, ENTJs are well served to seek the advice of a trusted Feeling type.",
  ENTJ_TITLE1P4:
    'When striving toward a goal, ENTJs often put personal needs aside until the work is done (and may expect others to do the same). For this reason, ENTJs may be considered self-sacrificing by some, but "cold and heartless" by others, especially those who prefer Feeling.',

  ENFP_TITLE1: "INTRO",
  ENFP_TITLE1P1:
    "ENFPs are initiators of change, keenly perceptive of possibilities. They energize and stimulate others through their contagious enthusiasm. They prefer the start-up phase of a project or relationship, and are tireless in the pursuit of new-found interests. ENFPs are able to anticipate the needs of others and to offer them needed help and appreciation. They bring zest, joy, liveliness, and fun to all aspects of their lives. They are at their best in fluid situations that allow them to express their creativity and use their charisma. They tend to idealize people, and can be disappointed when reality fails to fulfill their expectations. They are easily frustrated if a project requires a great deal of follow-up or attention to detail.",

  ENFJ_TITLE1: "INTRO",
  ENFJ_TITLE1P1:
    "Extraverted feeling types seek continuity through harmonious relationships and collective values. They excel at picking up on the tone of a situation and acting accordingly, adding warmth to a cool setting or turning sour into sweet. They naturally seek to know what people do well, what they enjoy, and where and how they work. They seem to have an infinite number of acquaintances from all walks of life and are always on the lookout for people in need and those who can help out. ENFJs weave and strengthen the collective fabric of social conventions and interactions. Inclusiveness is important and they are particularly sensitive to those who are excluded.",
  ENFJ_TITLE1P2:
    "ENFJs focus on others, feeling a glow when those around them are happy, and troubled when something is amiss. They are natural cheerleaders, often expressing support, gratitude, and encouragement, and heaping praise onto those they appreciate. They take note of what is being done and what needs doing, offering their assistance wherever necessary.",
  ENFJ_TITLE1P3:
    "ENFJs enjoy organizing group activities and tend to take their commitments seriously. In general, they are reliable and do not like to disappoint others. As team players and project leaders, they have a gift for rallying their players, focusing on what is being done right and each member's strengths. They are loyal and they expect loyalty. They carry conversations well, finding common ground with their speaker. They tend to find the correct and gracious way to respond in any given situation, no matter how tense or uncomfortable it is.",
  ENFJ_TITLE1P4:
    "Types with dominant extraverted feeling may uphold a wide range of values, simply because shared values are what create harmony. Some will profess the importance of tough-minded logic, justice and scholarly debate because their environments have these shared values. They tend to adopt the collective values of those in their social group.",

  ESTP_TITLE1: "INTRO",
  ESTP_TITLE1P1:
    "According to Myers-Briggs, ESTPs are hands-on learners who live in the moment, seeking the best in life, wanting to share it with their friends. The ESTP is open to situations, able to improvise to bring about desired results. They are active people who want to solve their problems rather than simply discuss them.",
  ESTP_TITLE1P2:
    "According to Keirsey, ESTPs, or \"Promoter Artisans\", are the most adept among the types at influencing other people. Promoting is the art of maneuvering others to one's position. Concrete in speech and utilitarian in action, they are smooth operators. The ESTP knows everyone who matters and everything there is to do because they are very resourceful, always knowing where the fun and action is. They like to indulge themselves in the finer things in life and to bring other people with them. Their goal in life is to sell themselves and their ideas to others. Dramatic and debonair, they are gifted at earning others' confidence.",
  ESTP_TITLE1P3:
    "According to Keirsey, based on observations of behavior, notable Promoters might include John F. Kennedy, Teddy Roosevelt, Madonna, and Donald Trump.",

  ESFJ_TITLE1: "INTRO",
  ESFJ_TITLE1P1:
    "ESFJs focus on the outside world and assess their experiences subjectively. They largely base their judgments on their belief system and on the effects of actions on people. ESFJs are literal and concrete, trusting the specific, factual information gathered through their physiological senses.",
  ESFJ_TITLE1P2:
    "ESFJs project warmth through a genuine interest in the well-being of others. They are often skilled at bringing out the best in people, and they want to understand other points of view. They are serious about their responsibilities, seeing what needs to be done and then doing it. Generally proficient at detailed tasks, they enjoy doing little things that make life easier for others. They value tradition and the security it offers.",
  ESFJ_TITLE1P3:
    "Easily hurt, ESFJs seek approval. They take pleasure in other people's happiness. They give generously but expect appreciation in return. Sensitive to the physical needs of others, they respond by offering practical care. As expert people readers, ESFJs often adapt their manner to meet the expectations of others. However, they may have difficulty recognizing the shortcomings of loved ones.",
  ESFJ_TITLE1P4:
    "ESFJs tend to be vocal in expressing their sense of right and wrong. Their value system derives from the external standards defined by their community, as opposed to a personal set of ethics. (This is one of the traits that distinguishes them from their ENFJ counterparts.) ESFJs raised in an environment of high ethical standards tend to display true generosity and kindness. However, those who grow up surrounded by a skewed set of values may develop a false sense of integrity and use their people skills to selfishly manipulate others articularly if their intuition is poorly developed, leaving them unable to foresee the consequences of their actions.",
  ESFJ_TITLE1P5:
    "ESFJs seek structured, controlled environments, and tend to be good at creating a sense of order. They generally feel insecure in an atmosphere of uncertainty. They value the rule of law and expect the same of others. ESFJs may be less interested in understanding the concepts behind the rules, tending to shy away from the abstract and impersonal.",

  ESFP_TITLE1: "INTRO",
  ESFP_TITLE1P1:
    "ESFPs live in the moment, experiencing life to the fullest. They enjoy people, as well as material comforts. Rarely allowing conventions to interfere with their lives, they find creative ways to meet human needs. ESFPs are excellent team players, focused on completing the task at hand with maximum fun and minimum discord. Active types, they find pleasure in new experiences.",
  ESFP_TITLE1P2:
    "ESFPs take a hands-on approach in most things. Because they learn more by doing than by studying or reading, they tend to rush into things, learning by interacting with their environment. They usually dislike theory and written explanations. Traditional schools can be difficult for ESFPs, although they tend to do well when the subject of study interests them, or when they see the relevance of a subject and are allowed to interact with people.",
  ESFP_TITLE1P3:
    "Observant, practical, realistic, and specific, ESFPs make decisions according to their own personal standards. They use their Feeling judgment internally to identify and empathize with others. Naturally attentive to the world around them, ESFPs are keen observers of human behavior. They quickly sense what is happening with other people and immediately respond to their individual needs. They are especially good at mobilizing people to deal with crises. Generous, optimistic, and persuasive, they are good at interpersonal interactions. They often play the role of peacemaker due to their warm, sympathetic, and tactful nature.",
  ESFP_TITLE1P4:
    "ESFPs love being around people and having new experiences. Living in the here-and-now, they often do not think about long term effects or the consequences of their actions. While very practical, they generally despise routines, instead desiring to 'go with the flow.' They are, in fact, very play minded. Because ESFPs learn better through hands-on experience, classroom learning may be troublesome for many of them, especially those with a very underdeveloped intuitive side.[4]",
  ESFP_TITLE1P5:
    "Others usually see ESFPs as resourceful and supportive, as well as gregarious, playful, and spontaneous. ESFPs get a lot of satisfaction out of life and are fun to be around. Their exuberance and enthusiasm draw others to them. They are flexible, adaptable, congenial, and easygoing. They seldom plan ahead, trusting their ability to respond in the moment and deal effectively with whatever presents itself. They dislike structure and routine and will generally find ways to bend the rules.[4]",
  ESFP_TITLE1P6:
    "Sometimes life circumstances do not support ESFPs in the development and expression of the Feeling and Sensing preferences. If they have not developed their Feeling preference, ESFPs may get caught up in the interactions of the moment, with no mechanism for weighing, evaluating, or anchoring themselves. If they have not developed their Sensing preference, they may focus on the sensory data available in the moment. Their decisions may then be limited to gratification of sensual desires, particularly those involving interactions with other people.",
  ESFP_TITLE1P7:
    "If ESFPs do not find a place where they can use their gifts and be appreciated for their contributions, they usually feel frustrated and may become distracted or overly impulsive. They may have trouble accepting and meeting deadlines. They may also become hypersensitive, internalizing others’ actions and decisions.",
  ESFP_TITLE1P8:
    "It is natural for ESFPs to give less attention to their non-preferred Intuitive and Thinking parts. If they neglect these too much, they may fail to look at long-term consequences, acting on immediate needs of themselves and others. They may also avoid complex or ambiguous situations and people, putting enjoyment ahead of obligations.",
  ESFP_TITLE1P9:
    "Under great stress, ESFPs may feel overwhelmed internally by negative possibilities. They then put energy into developing simplistic global explanations for their negativity.",

  ESTJ_TITLE1: "INTRO",
  ESTJ_TITLE1P1:
    "ESTJs are practical, realistic, and matter-of-fact, with a natural head for business or mechanics. Though they are not interested in subjects they see no use for, they can apply themselves when necessary. They like to organize and run activities. ESTJs make good administrators, especially if they remember to consider others' feelings and points of view, which they often miss.",
  ESTJ_TITLE1P2:
    "According to Keirsey, ESTJs are civic-minded individuals who dedicate themselves to maintaining the institutions behind a smooth-running society. They are defenders of the status quo and strong believers in rules and procedures. ESTJs are outgoing and do not hesitate to communicate their opinions and expectations to others.",

  INFJ_TITLE1: "INTRO",
  INFJ_TITLE1P1:
    "INFJs are conscientious and value-driven. They seek meaning in relationships, ideas, and events, with an eye toward better understanding themselves and others. Using their intuitive skills, they develop a clear and confident vision, which they then set out to execute, aiming to better the lives of others. Like their INTJ counterparts, INFJs regard problems as opportunities to design and implement creative solutions.",
  INFJ_TITLE1P2:
    "INFJs are quiet, private individuals who prefer to exercise their influence behind the scenes. Although very independent, INFJs are intensely interested in the well-being of others. INFJs prefer one-on-one relationships to large groups. Sensitive and complex, they are adept at understanding complicated issues and driven to resolve differences in a cooperative and creative manner.",
  INFJ_TITLE1P3:
    'INFJs have a rich, vivid inner life, which they may be reluctant to share with those around them. Nevertheless, they are congenial in their interactions, and perceptive of the emotions of others. Generally well-liked by their peers, they may often be considered close friends and confidants by most other types. However, they are guarded in expressing their own feelings, especially to new people, and so tend to establish close relationships slowly. INFJs tend to be easily hurt, though they may not reveal this except to their closest companions. INFJs may "silently withdraw as a way of setting limits", rather than expressing their wounded feelings—a behavior that may leave others confused and upset.',
  INFJ_TITLE1P4:
    "INFJs tend to be sensitive, quiet leaders with a great depth of personality. They are intricately and deeply woven, mysterious, and highly complex, sometimes puzzling even to themselves. They have an orderly view toward the world, but are internally arranged in a complex way that only they can understand. Abstract in communicating, they live in a world of hidden meanings and possibilities. With a natural affinity for art, INFJs tend to be creative and easily inspired. Yet they may also do well in the sciences, aided by their intuition.",

  INFP_TITLE1: "INTRO",
  INFP_TITLE1P1:
    "According to Myers-Briggs, INFPs focus much of their energy on an inner world dominated by intense feeling and deeply held ethics. They seek an external life that is in keeping with these values. Loyal to the people and causes important to them, INFPs can quickly spot opportunities to implement their ideals. They are curious to understand those around them, and so are accepting and flexible except when their values are threatened.",
  INFP_TITLE1P2:
    "According to Keirsey, based on observations of behavior, notable INFPs may include Princess Diana, George Orwell, Aldous Huxley, Audrey Hepburn, Richard Gere, Albert Schweitzer and Isabel Myers.",
  INFP_TITLE1P3:
    "The polite, reserved exterior of INFPs can at first make them difficult to get to know. They enjoy conversation, however, taking particular delight in the unusual. When INFPs are in a sociable mood, their humor and charm shine through. Disposed to like people and to avoid conflict, INFPs tend to make pleasant company.",
  INFP_TITLE1P4:
    "Devoted to those in their inner circle, INFPs guard the emotional well-being of others, consoling those in distress. Guided by their desire for harmony, INFPs prefer to be flexible unless their ethics are violated. Then, they become passionate advocates for their beliefs. They are often able to sway the opinions of others through tact, diplomacy, and an ability to see varying sides of an issue.",
  INFP_TITLE1P5:
    "INFPs develop these insights through reflection, and they require substantial time alone to ponder and process new information. While they can be quite patient with complex material, they are generally bored by routine. Though not always organized, INFPs are meticulous about things they value. Perfectionists, they may have trouble completing a task because it cannot meet their high standards. They may even go back to a completed project after the deadline so they can improve it.",
  INFP_TITLE1P6:
    "INFPs are creative types and often have a gift for language. As introverts, they may prefer to express themselves through writing. Their dominant Feeling drives their desire to communicate, while their auxiliary intuition supplies the imagination. Having a talent for symbolism, they enjoy metaphors and similes. They continually seek new ideas and adapt well to change. They prefer working in an environment that values these gifts and allows them to make a positive difference in the world, according to their personal beliefs.",

  INTP_TITLE1: "INTRO",
  INTP_TITLE1P1:
    'INTPs are quiet, thoughtful, analytical individuals who tend to spend long periods of time on their own, working through problems and forming solutions. They are curious about systems and how things work. Consequently, they are frequently found in careers such as science, philosophy, law, and architecture. INTPs tend to be less at ease in social situations or in the "caring professions", although they enjoy the company of those who share their interests. They prize autonomy in themselves and others. They generally balk at attempts by others to convince them to change. They also tend to be impatient with the bureaucracy, rigid hierarchies, and the politics prevalent in many professions. INTPs have little regard for titles and badges, which they often consider to be unjustified. INTPs usually come to distrust authority as hindering the uptake of novel ideas and the search for knowledge. INTPs accept ideas based on merit, rather than tradition or authority. They have little patience for social customs that seem illogical or that serve as obstacles for pursuing ideas and knowledge. This may place them at odds with people who have an SJ preference, since SJs tend to defer to authority, tradition, and what the rest of the group is doing. INTPs prefer to work informally with others as equals.',
  INTP_TITLE1P2:
    "INTPs organize their understanding of any topic by articulating principles, and they are especially drawn to theoretical constructs. Having articulated these principles for themselves, they can demonstrate remarkable skill in explaining complex ideas to others in simple terms, especially in writing. On the other hand, their ability to grasp complexity may also lead them to provide overly detailed explanations of simple ideas, and listeners may judge that the INTP makes things more difficult than they need to be. To the INTPs' mind, they are presenting all the relevant information or trying to crystallize the concept as clearly as possible.",
  INTP_TITLE1P3:
    "Given their independent nature, INTPs may prefer working alone to leading or following in a group. During interactions with others, if INTPs are focused on gathering information, they may seem oblivious, aloof, or even rebellious—when in fact they are concentrating on listening and understanding. However, INTPs' extraverted intuition often gives them a quick wit, especially with language. They may defuse tension through comical observations and references. They can be charming, even in their quiet reserve, and are sometimes surprised by the high esteem in which their friends and colleagues hold them.",
  INTP_TITLE1P4:
    "INTPs are driven to fully understand a discussion from all relevant angles. Their impatience with seemingly indefensible ideas can make them particularly devastating at debate. When INTPs feel insulted, they may respond with sudden, cutting criticism. After such an incident, INTPs are likely to be as bewildered as the recipient. They have broken the rules of debate and exposed their raw emotions. To INTPs, this is the crux of the problem: improperly handled emotions, INTPs believe, can only harm. While INTPs experience emotions as an important part of their internal lives, and sometimes share their emotions with others, INTPs nevertheless believe that emotions must not play a role in logical discussions, or be expressed in a way that would put themselves at disadvantage.",
  INTP_TITLE1P5:
    "According to Keirsey, based on behavioral characteristics, notable Architects might include Albert Einstein, Charles Darwin, and Thomas Jefferson.",

  ISFJ_TITLE1: "INTRO",
  ISFJ_TITLE1P1:
    "According to Myers-Briggs, ISFJs are interested in maintaining order and harmony in every aspect of their lives. They are steadfast and meticulous in handling their responsibilities. Although quiet, they are people-oriented and very observant. Not only do they remember details about others, but they observe and respect others’ feelings. Friends and family are likely to describe them as thoughtful and trustworthy.",
  ISFJ_TITLE1P2:
    'According to Keirsey, ISFJs, or "Protector Guardians", are most concerned with taking care of people by keeping them safe and secure. They are modest caretakers who do not demand credit or thanks for their efforts. But while they are essentially compassionate—and in fact exercise more patience in dealing with people with disabilities than perhaps any other type—their shyness with strangers can lead others to misread them as standoffish. Only among friends and family may this quiet type feel comfortable speaking freely. ISFJs are serious people with a strong work ethic, not inclined to self-indulgence. They believe in being meticulous and thrifty. They work well alone. While they may enjoy taking care of others, they do not enjoy giving orders.',

  ISFP_TITLE1: "INTRO",
  ISFP_TITLE1P1:
    'According to Myers-Briggs, ISFPs are peaceful, easygoing people who adopt a "live and let live" approach to life. They enjoy taking things at their own pace and tend to live in the moment. Although quiet, they are pleasant, considerate, caring, and devoted to the people in their lives. Though not inclined to debate or necessarily even air their views, their values are important to them.',
  ISFP_TITLE1P2:
    "According to Keirsey, Composer Artisans are grounded in the here and now. They are extremely sensitive to their environment, attuned to the perceptions of their five senses even more than other sensing types are. They notice small variations in their physical world or in the people around them. They are very sensitive to balance and understand well what does or does not fit, whether in a work of art or any other aspect of their lives. ISFPs are highly conscious of their companions, but they prefer to allow others to direct their own lives. ISFPs tend to be emotionally well rounded and empathetic toward others.",

  ISTJ_TITLE1: "INTRO",
  ISTJ_TITLE1P1:
    "ISTJs are faithful, logical, organized, sensible, and earnest traditionalists who enjoy keeping their lives and environments well-regulated. Typically reserved and serious individuals, they earn success through their thoroughness and extraordinary dependability. They are capable of shutting out distractions in order to take a practical, logical approach to their endeavors, and are able to make the tough decisions that other types avoid. Realistic and responsible, ISTJs are often seen as worker bees striving steadily toward their goals. They take special joy in maintaining institutions and are often highly religious. Despite their dependability and good intentions, however, ISTJs can experience difficulty in understanding and responding to the emotional needs of others. [12] Although they often focus on their internal world, ISTJs prefer dealing with the present and the factual. They are detail-oriented and weigh various options when making decisions, although they generally stick to the conventional. ISTJs are well-prepared for eventualities and have a good understanding of most situations. They believe in practical objectives, and they value traditions and loyalty.",
  ISTJ_TITLE1P2:
    "ISTJs learn best and apply themselves to subjects that they deem practical and useful. They bring painstaking attention to detail in their work and will not rest until a concept is fully learned or a job is well completed. [13] As learners, ISTJs tend to need materials, directions, and teachers to be precise and accurate if they are to trust the information that is presented. They prefer concrete and useful applications and will tolerate theory only if it leads to these ends. They like learning activities that allow them time to reflect and think. Material that seems too easy or too enjoyable leads ISTJs to be skeptical of its merit. Because of their practical outlook, ISTJs clearly delineate between work and play. Therefore, their ideal learning environment is task-oriented, has a clear schedule, and has a clear and precise assignment.",
  ISTJ_TITLE1P3:
    "ISTJs respect facts. They hold a tremendous store of data within themselves, gathered through their Sensing function. They may have difficulty valuing a theory or idea that differs from their own perspective. However, if they are shown the importance or relevance of the idea by someone whom they respect or care about, the idea becomes a fact that the ISTJ will internalize and vigorously support. ISTJs often work for long periods, devoting their energy to tasks that they see as important to fulfilling a goal. However, they resist putting energy into things that don't make sense to them, or for which they can't see a practical application. They prefer to work alone but can work well in teams when the situation demands it. They like to be accountable for their actions, and they enjoy positions of responsibility. They have little use for theory or abstract thinking, unless the practical application is clear.",
  ISTJ_TITLE1P4:
    "In general, ISTJs are capable, logical, reasonable, and effective individuals with a deeply driven desire to promote security and peaceful living. They can be highly effective at achieving their goals—whatever those may be.",

  ISTP_TITLE1: "INTRO",
  ISTP_TITLE1P1:
    "According to Myers-Briggs, ISTPs excel at analyzing situations to reach the heart of a problem so that they can swiftly implement a functional repair, making them ideally suited to the field of engineering. Naturally quiet people, they are interested in understanding how systems operate, focusing on efficient operation and structure. They are open to new information and approaches. But contrary to their seemingly detached natures, ISTPs are often capable of humorously insightful observations about the world around them. They can also be closet daredevils who gravitate toward fast-moving or risky hobbies (such as bungee jumping, hang gliding, racing, motorcycling, and parachuting), recreational sports (such as downhill skiing, paintball, ice hockey, and scuba diving), and careers (such as aviation and firefighting).",
  ISTP_TITLE1P2:
    "ISTPs may sometimes seem to act without regard for procedures, directions, protocol, or even their own safety. But while their approach may seem haphazard, it is in fact based on a broad store of knowledge developed over time through action and keen observation. ISTPs enjoy self-sufficiency and take pride in developing their own solutions to problems.",
  ISTP_TITLE1P3:
    'ISTPs are content to let others live according to their own rules, as long as the favor is reciprocated. ISTPs endure reasonable impositions without complaint—but if their "territory" is encroached upon, eroded, or violated, they defend what they view as rightfully theirs.',

  INTJ_TITLE1: "INTRO",
  INTJ_TITLE1P1:
    "INTJs are analytical. Like INTPs, they are most comfortable working alone and tend to be less sociable than other types. Nevertheless, INTJs are prepared to lead if no one else seems up to the task, or if they see a major weakness in the current leadership. They tend to be pragmatic, logical, and creative. They have a low tolerance for spin or rampant emotionalism. They are not generally susceptible to catchphrases and do not recognize authority based on tradition, rank, or title.",
  INTJ_TITLE1P2:
    "Hallmarks of the INTJ include independence of thought and a desire for efficiency. They work best when given autonomy and creative freedom. They harbor an innate desire to express themselves by conceptualizing their own intellectual designs. They have a talent for analyzing and formulating complex theories. INTJs are generally well-suited for occupations within academia, research, consulting, management, science, engineering, and law. They are often acutely aware of their own knowledge and abilities—as well as their limitations and what they don't know (a quality that tends to distinguish them from INTPs). INTJs thus develop a strong confidence in their ability and talents, making them natural leaders.",
  INTJ_TITLE1P3:
    "In forming relationships, INTJs tend to seek out others with similar character traits and ideologies. Agreement on theoretical concepts is an important aspect of their relationships. By nature INTJs can be demanding in their expectations, and approach relationships in a rational manner. As a result, INTJs may not always respond to a spontaneous infatuation but wait for a mate who better fits their set criteria. They tend to be stable, reliable, and dedicated. Harmony in relationships and home life tends to be extremely important to them. They generally withhold strong emotion and do not like to waste time with what they consider irrational social rituals. This may cause non-INTJs to perceive them as distant and reserved; nevertheless, INTJs are usually very loyal partners who are prepared to commit substantial energy and time into a relationship to make it work.",
  INTJ_TITLE1P4:
    "As mates, INTJs want harmony and order in the home and in relationships. The most independent of all types, INTJs trust their intuition when choosing friends and mates—even in spite of contradictory evidence or pressure from others. The emotions of an INTJ are hard to read, and neither male nor female INTJs are apt to express emotional reactions. At times, INTJs seem cold, reserved, and unresponsive, while in fact they are almost hypersensitive to signals of rejection from those they care for. In social situations, INTJs may also be unresponsive and may neglect small rituals designed to put others at ease. For example, INTJs may communicate that idle dialogue such as small talk is a waste of time. This may create the impression that the INTJ is in a hurry—an impression that is not always intended. In their interpersonal relationships, INTJs are usually better in a working situation than in a recreational situation.",

  End: "",
};
