import { Injectable } from "@angular/core";
import { StorageService } from "../common/storage.service";
import { CommonService } from "../common/common.service";
import { HttpClient } from "@angular/common/http";
import {
  RedditObject,
  RedditPictureList,
  RedditPost,
  RedditTextObject,
} from "../../models/index";

@Injectable({
  providedIn: "root",
})
export class RedditService {
  comments: string[] = [];

  constructor(
    public store: StorageService,
    public HttpClient: HttpClient,
    public commonService: CommonService
  ) {
    //console.log('reddit service');
  }

  async getInitSubReddit(subreddit: string) {
    await this.HttpClient.get(
      "https://www.reddit.com/r/" + subreddit + ".json"
    ).subscribe(
      // await this.HttpClient.get('https://www.reddit.com/r/AskReddit/new.json').subscribe(
      async (data: any) => {
        let redditPost: RedditPost[] = [];
        // let postTitles: string[] = [];
        let redditPagingIndex: string = "";
        let permalink: string = "";
        for (let i = 0; i < 25; i++) {
          permalink =
            "https://www.reddit.com" + data.data.children[i].data.permalink;
          //console.log(i + " " + 'this permalink: ' + permalink)
          let is_reddit_media_domain =
            data.data.children[i].data.is_reddit_media_domain;
          let crosspost_parent_list =
            data.data.children[i].data.crosspost_parent_list;
          let title: string = data.data.children[i].data.title
            .replace(/[\u200B-\u200D\uFEFF]/g, "")
            .replace(/&amp;/g, "&");
          let nsfw: boolean = data.data.children[i].data.over_18;
          let permalinkSubstr = permalink.substr(25, 40);
          let permalinkSubstrFind = permalinkSubstr.indexOf("/");
          let subReddit = permalink.substr(25, permalinkSubstrFind);
          if (
            !nsfw &&
            !title.includes("egathread") &&
            !title.includes("hread") &&
            !title.includes("iscussion")
          ) {
            //console.log("permalink: " + permalink);
            let secure_media_embed = JSON.stringify(
              data.data.children[i].data.secure_media_embed
            );
            let is_video: boolean = data.data.children[i].data.is_video;
            if (is_reddit_media_domain == true && is_video) {
              if (crosspost_parent_list == undefined) {
                let fallback_url: string =
                  data.data.children[i].data.secure_media.reddit_video
                    .fallback_url;
                var dashIndex = fallback_url.indexOf("DASH_");
                let redditPostPermalink = permalink;
                //console.log("title: " + title);
                //console.log("permalink: " + redditPostPermalink);
                await this.HttpClient.get(permalink + ".json").subscribe(
                  async (data: any) => {
                    //console.log("permalink: " + permalink);
                    let replyNumber = data[1].data.children.length;
                    if (replyNumber >= 6) {
                      let comments: string[] = [];
                      for (let index = 1; index < 6; index++) {
                        let commentToAdd = data[1].data.children[
                          index
                        ].data.body
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        let author = data[1].data.children[index].data.author
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        if (
                          !(
                            commentToAdd == "[removed]" ||
                            commentToAdd == "[deleted]" ||
                            commentToAdd.length > 400
                          )
                        ) {
                          comments.push(
                            "<u>" + author + "</u>: " + commentToAdd
                          );
                        }
                      }
                      //console.log("permalink: " + permalink);
                      redditPost.push({
                        title: title,
                        permalink: redditPostPermalink,
                        postType: "video",
                        videoURL: fallback_url,
                        audioURL:
                          fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                        pictureURL: null,
                        textDescription: null,
                        shortTextDescription: null,
                        embedded: null,
                        comments: comments,
                        authors: null,
                        subReddit: subReddit,
                      });
                      await this.store.setData("redditPost", redditPost);
                    } else if (replyNumber > 2) {
                      let comments: string[] = [];
                      for (let index = 1; index < replyNumber - 1; index++) {
                        let commentToAdd = data[1].data.children[
                          index
                        ].data.body
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        let author = data[1].data.children[index].data.author
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        if (
                          !(
                            commentToAdd == "[removed]" ||
                            commentToAdd == "[deleted]" ||
                            commentToAdd.length > 400
                          )
                        ) {
                          comments.push(
                            "<u>" + author + "</u>: " + commentToAdd
                          );
                        }
                      }
                      //console.log("permalink: " + permalink);
                      redditPost.push({
                        title: title,
                        permalink: redditPostPermalink,
                        postType: "video",
                        videoURL: fallback_url,
                        audioURL:
                          fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                        pictureURL: null,
                        textDescription: null,
                        shortTextDescription: null,
                        embedded: null,
                        comments: comments,
                        authors: null,
                        subReddit: subReddit,
                      });
                      await this.store.setData("redditPost", redditPost);
                    }
                  }
                );
              } else {
                let fallback_url: string =
                  crosspost_parent_list[0].secure_media.reddit_video
                    .fallback_url;
                var dashIndex = fallback_url.indexOf("DASH_");
                let redditPostPermalink = permalink;
                //console.log("title: " + title);
                //console.log("permalink: " + redditPostPermalink);
                await this.HttpClient.get(permalink + ".json").subscribe(
                  async (data: any) => {
                    //console.log("permalink: " + permalink);
                    let replyNumber = data[1].data.children.length;
                    if (replyNumber >= 6) {
                      let comments: string[] = [];
                      for (let index = 1; index < 6; index++) {
                        let commentToAdd = data[1].data.children[
                          index
                        ].data.body
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        let author = data[1].data.children[index].data.author
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        if (
                          !(
                            commentToAdd == "[removed]" ||
                            commentToAdd == "[deleted]" ||
                            commentToAdd.length > 400
                          )
                        ) {
                          comments.push(
                            "<u>" + author + "</u>: " + commentToAdd
                          );
                        }
                      }
                      redditPost.push({
                        title: title,
                        permalink: redditPostPermalink,
                        postType: "video",
                        videoURL: fallback_url,
                        audioURL:
                          fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                        pictureURL: null,
                        textDescription: null,
                        shortTextDescription: null,
                        embedded: null,
                        comments: comments,
                        authors: null,
                        subReddit: subReddit,
                      });
                      await this.store.setData("redditPost", redditPost);
                    } else if (replyNumber > 2) {
                      let comments: string[] = [];
                      for (let index = 1; index < replyNumber - 1; index++) {
                        let commentToAdd = data[1].data.children[
                          index
                        ].data.body
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        let author = data[1].data.children[index].data.author
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        if (
                          !(
                            commentToAdd == "[removed]" ||
                            commentToAdd == "[deleted]" ||
                            commentToAdd.length > 400
                          )
                        ) {
                          comments.push(
                            "<u>" + author + "</u>: " + commentToAdd
                          );
                        }
                      }
                      redditPost.push({
                        title: title,
                        permalink: redditPostPermalink,
                        postType: "video",
                        videoURL: fallback_url,
                        audioURL:
                          fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                        pictureURL: null,
                        textDescription: null,
                        shortTextDescription: null,
                        embedded: null,
                        comments: comments,
                        authors: null,
                        subReddit: subReddit,
                      });
                      await this.store.setData("redditPost", redditPost);
                    }
                    await this.store.setData("redditPost", redditPost);
                  }
                );
              }
            } else if (is_reddit_media_domain == true && !is_video) {
              let url = data.data.children[i].data.url;
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  let replyNumber = data[1].data.children.length;
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "picture",
                      videoURL: null,
                      audioURL: null,
                      pictureURL: url,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData("redditPost", redditPost);
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "picture",
                      videoURL: null,
                      audioURL: null,
                      pictureURL: url,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData("redditPost", redditPost);
                  }
                }
              );
            } else if (secure_media_embed == "{}") {
              // text post
              let textDescription: string = data.data.children[i].data.selftext
                .replace(/[\u200B-\u200D\uFEFF]/g, "")
                .replace(/&amp;/g, "&");
              let shortTextDescription: string;
              let lastIndex: number;
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              if (textDescription.length >= 142) {
                lastIndex = textDescription.substr(0, 142).lastIndexOf(" ");
                shortTextDescription =
                  textDescription.substr(0, lastIndex) + "<i>...see more</i>";
              }
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  let replyNumber = data[1].data.children.length;
                  // keep in mind the last children is not the standard structure (prob for paging info)
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    let url_embed =
                      data[0].data.children[0].data.url_overridden_by_dest;
                    // ALEX TO DO: need to display the image
                    let postType: string = "embedWeb";
                    let pictureURL: string;
                    // this.getHTMLMediaByTag(url_embed);
                    if (url_embed == undefined) {
                      url_embed = null;
                      postType = "text";
                    }
                    // alert("postType: " + postType);
                    //console.log("url_embed: " + url_embed);
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: postType,
                      videoURL: null,
                      audioURL: null,
                      pictureURL: null,
                      textDescription: textDescription,
                      shortTextDescription: shortTextDescription,
                      embedded: url_embed,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData("redditPost", redditPost);
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    let url_embed =
                      data[0].data.children[0].data.url_overridden_by_dest;
                    let postType: string = "embedWeb";
                    let pictureURL: string;
                    // this.getHTMLMediaByTag(url_embed);
                    // await console.log("pictureURL: " + pictureURL);
                    if (url_embed == undefined) {
                      url_embed = null;
                      postType = "text";
                    }
                    // alert("postType: " + postType);
                    //console.log("url_embed: " + url_embed);
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: postType,
                      videoURL: null,
                      audioURL: null,
                      pictureURL: null,
                      textDescription: textDescription,
                      shortTextDescription: shortTextDescription,
                      embedded: url_embed,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                  }
                  //console.log("picture list: " + JSON.stringify(redditPost))
                }
              );
            } else if (secure_media_embed != "{}") {
              // embed posts
              let secure_media_embed =
                data.data.children[i].data.secure_media_embed.media_domain_url;
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              //console.log("secure_media_embed: " + JSON.stringify(secure_media_embed));
              //console.log("permalink: " + i + ": " + permalink);
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  let replyNumber = data[1].data.children.length;
                  // keep in mind the last children is not the standard structure (prob for paging info)
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "embed",
                      videoURL: null,
                      audioURL: null,
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: secure_media_embed,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData("redditPost", redditPost);
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "embed",
                      videoURL: null,
                      audioURL: null,
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: secure_media_embed,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                  }
                  //console.log("picture list: " + JSON.stringify(redditPost))
                }
              );
            } else {
              console.log("all else permalink: " + i + ": " + permalink);
            }
            if (i % 24 == 0) {
              redditPagingIndex = data.data.after;
            }
          }
        }
        await this.store.setData("redditPost", redditPost);
        await this.store.setData("redditPagingIndex", redditPagingIndex);
      }
    );
  }

  async getPreferenceInitSubReddit(subreddit: string) {
    await this.HttpClient.get(
      "https://www.reddit.com/r/" + subreddit + ".json"
    ).subscribe(
      // await this.HttpClient.get('https://www.reddit.com/r/AskReddit/new.json').subscribe(
      async (data: any) => {
        let redditPost: RedditPost[] = [];
        // let postTitles: string[] = [];
        let redditPagingIndex: string = "";
        let permalink: string = "";
        for (let i = 0; i < 25; i++) {
          permalink =
            "https://www.reddit.com" + data.data.children[i].data.permalink;
          //console.log(i + " " + 'this permalink: ' + permalink)
          let is_reddit_media_domain =
            data.data.children[i].data.is_reddit_media_domain;
          let crosspost_parent_list =
            data.data.children[i].data.crosspost_parent_list;
          let title: string = data.data.children[i].data.title
            .replace(/[\u200B-\u200D\uFEFF]/g, "")
            .replace(/&amp;/g, "&");
          let nsfw: boolean = data.data.children[i].data.over_18;
          let permalinkSubstr = permalink.substr(25, 40);
          let permalinkSubstrFind = permalinkSubstr.indexOf("/");
          let subReddit = permalink.substr(25, permalinkSubstrFind);
          if (
            !nsfw &&
            !title.includes("egathread") &&
            !title.includes("hread") &&
            !title.includes("iscussion")
          ) {
            //console.log("permalink: " + permalink);
            let secure_media_embed = JSON.stringify(
              data.data.children[i].data.secure_media_embed
            );
            let is_video: boolean = data.data.children[i].data.is_video;
            if (is_reddit_media_domain == true && is_video) {
              if (crosspost_parent_list == undefined) {
                let fallback_url: string =
                  data.data.children[i].data.secure_media.reddit_video
                    .fallback_url;
                var dashIndex = fallback_url.indexOf("DASH_");
                let redditPostPermalink = permalink;
                //console.log("title: " + title);
                //console.log("permalink: " + redditPostPermalink);
                await this.HttpClient.get(permalink + ".json").subscribe(
                  async (data: any) => {
                    //console.log("permalink: " + permalink);
                    let replyNumber = data[1].data.children.length;
                    if (replyNumber >= 6) {
                      let comments: string[] = [];
                      for (let index = 1; index < 6; index++) {
                        let commentToAdd = data[1].data.children[
                          index
                        ].data.body
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        let author = data[1].data.children[index].data.author
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        if (
                          !(
                            commentToAdd == "[removed]" ||
                            commentToAdd == "[deleted]" ||
                            commentToAdd.length > 400
                          )
                        ) {
                          comments.push(
                            "<u>" + author + "</u>: " + commentToAdd
                          );
                        }
                      }
                      //console.log("permalink: " + permalink);
                      redditPost.push({
                        title: title,
                        permalink: redditPostPermalink,
                        postType: "video",
                        videoURL: fallback_url,
                        audioURL:
                          fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                        pictureURL: null,
                        textDescription: null,
                        shortTextDescription: null,
                        embedded: null,
                        comments: comments,
                        authors: null,
                        subReddit: subReddit,
                      });
                      await this.store.setData("redditPreferencePost", redditPost);
                    } else if (replyNumber > 2) {
                      let comments: string[] = [];
                      for (let index = 1; index < replyNumber - 1; index++) {
                        let commentToAdd = data[1].data.children[
                          index
                        ].data.body
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        let author = data[1].data.children[index].data.author
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        if (
                          !(
                            commentToAdd == "[removed]" ||
                            commentToAdd == "[deleted]" ||
                            commentToAdd.length > 400
                          )
                        ) {
                          comments.push(
                            "<u>" + author + "</u>: " + commentToAdd
                          );
                        }
                      }
                      //console.log("permalink: " + permalink);
                      redditPost.push({
                        title: title,
                        permalink: redditPostPermalink,
                        postType: "video",
                        videoURL: fallback_url,
                        audioURL:
                          fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                        pictureURL: null,
                        textDescription: null,
                        shortTextDescription: null,
                        embedded: null,
                        comments: comments,
                        authors: null,
                        subReddit: subReddit,
                      });
                      await this.store.setData("redditPreferencePost", redditPost);
                    }
                  }
                );
              } else {
                let fallback_url: string =
                  crosspost_parent_list[0].secure_media.reddit_video
                    .fallback_url;
                var dashIndex = fallback_url.indexOf("DASH_");
                let redditPostPermalink = permalink;
                //console.log("title: " + title);
                //console.log("permalink: " + redditPostPermalink);
                await this.HttpClient.get(permalink + ".json").subscribe(
                  async (data: any) => {
                    //console.log("permalink: " + permalink);
                    let replyNumber = data[1].data.children.length;
                    if (replyNumber >= 6) {
                      let comments: string[] = [];
                      for (let index = 1; index < 6; index++) {
                        let commentToAdd = data[1].data.children[
                          index
                        ].data.body
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        let author = data[1].data.children[index].data.author
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        if (
                          !(
                            commentToAdd == "[removed]" ||
                            commentToAdd == "[deleted]" ||
                            commentToAdd.length > 400
                          )
                        ) {
                          comments.push(
                            "<u>" + author + "</u>: " + commentToAdd
                          );
                        }
                      }
                      redditPost.push({
                        title: title,
                        permalink: redditPostPermalink,
                        postType: "video",
                        videoURL: fallback_url,
                        audioURL:
                          fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                        pictureURL: null,
                        textDescription: null,
                        shortTextDescription: null,
                        embedded: null,
                        comments: comments,
                        authors: null,
                        subReddit: subReddit,
                      });
                      await this.store.setData("redditPreferencePost", redditPost);
                    } else if (replyNumber > 2) {
                      let comments: string[] = [];
                      for (let index = 1; index < replyNumber - 1; index++) {
                        let commentToAdd = data[1].data.children[
                          index
                        ].data.body
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        let author = data[1].data.children[index].data.author
                          .replace(/[\u200B-\u200D\uFEFF]/g, "")
                          .replace(/&amp;/g, "&");
                        if (
                          !(
                            commentToAdd == "[removed]" ||
                            commentToAdd == "[deleted]" ||
                            commentToAdd.length > 400
                          )
                        ) {
                          comments.push(
                            "<u>" + author + "</u>: " + commentToAdd
                          );
                        }
                      }
                      redditPost.push({
                        title: title,
                        permalink: redditPostPermalink,
                        postType: "video",
                        videoURL: fallback_url,
                        audioURL:
                          fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                        pictureURL: null,
                        textDescription: null,
                        shortTextDescription: null,
                        embedded: null,
                        comments: comments,
                        authors: null,
                        subReddit: subReddit,
                      });
                      await this.store.setData("redditPreferencePost", redditPost);
                    }
                    await this.store.setData("redditPreferencePost", redditPost);
                  }
                );
              }
            } else if (is_reddit_media_domain == true && !is_video) {
              let url = data.data.children[i].data.url;
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  let replyNumber = data[1].data.children.length;
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "picture",
                      videoURL: null,
                      audioURL: null,
                      pictureURL: url,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData("redditPreferencePost", redditPost);
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "picture",
                      videoURL: null,
                      audioURL: null,
                      pictureURL: url,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData("redditPreferencePost", redditPost);
                  }
                }
              );
            } else if (secure_media_embed == "{}") {
              // text post
              let textDescription: string = data.data.children[i].data.selftext
                .replace(/[\u200B-\u200D\uFEFF]/g, "")
                .replace(/&amp;/g, "&");
              let shortTextDescription: string;
              let lastIndex: number;
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              if (textDescription.length >= 142) {
                lastIndex = textDescription.substr(0, 142).lastIndexOf(" ");
                shortTextDescription =
                  textDescription.substr(0, lastIndex) + "<i>...see more</i>";
              }
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  let replyNumber = data[1].data.children.length;
                  // keep in mind the last children is not the standard structure (prob for paging info)
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    let url_embed =
                      data[0].data.children[0].data.url_overridden_by_dest;
                    // ALEX TO DO: need to display the image
                    let postType: string = "embedWeb";
                    let pictureURL: string;
                    // this.getHTMLMediaByTag(url_embed);
                    if (url_embed == undefined) {
                      url_embed = null;
                      postType = "text";
                    }
                    // alert("postType: " + postType);
                    //console.log("url_embed: " + url_embed);
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: postType,
                      videoURL: null,
                      audioURL: null,
                      pictureURL: null,
                      textDescription: textDescription,
                      shortTextDescription: shortTextDescription,
                      embedded: url_embed,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData("redditPreferencePost", redditPost);
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    let url_embed =
                      data[0].data.children[0].data.url_overridden_by_dest;
                    let postType: string = "embedWeb";
                    let pictureURL: string;
                    // this.getHTMLMediaByTag(url_embed);
                    // await console.log("pictureURL: " + pictureURL);
                    if (url_embed == undefined) {
                      url_embed = null;
                      postType = "text";
                    }
                    // alert("postType: " + postType);
                    //console.log("url_embed: " + url_embed);
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: postType,
                      videoURL: null,
                      audioURL: null,
                      pictureURL: null,
                      textDescription: textDescription,
                      shortTextDescription: shortTextDescription,
                      embedded: url_embed,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                  }
                  //console.log("picture list: " + JSON.stringify(redditPost))
                }
              );
            } else if (secure_media_embed != "{}") {
              // embed posts
              let secure_media_embed =
                data.data.children[i].data.secure_media_embed.media_domain_url;
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              //console.log("secure_media_embed: " + JSON.stringify(secure_media_embed));
              //console.log("permalink: " + i + ": " + permalink);
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  let replyNumber = data[1].data.children.length;
                  // keep in mind the last children is not the standard structure (prob for paging info)
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "embed",
                      videoURL: null,
                      audioURL: null,
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: secure_media_embed,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData("redditPreferencePost", redditPost);
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "embed",
                      videoURL: null,
                      audioURL: null,
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: secure_media_embed,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                  }
                  //console.log("picture list: " + JSON.stringify(redditPost))
                }
              );
            } else {
              console.log("all else permalink: " + i + ": " + permalink);
            }
            if (i % 24 == 0) {
              redditPagingIndex = data.data.after;
            }
          }
        }
        await this.store.setData("redditPreferencePost", redditPost);
        await this.store.setData("redditPreferencePagingIndex", redditPagingIndex);
      }
    );
  }

  async getPagingSubReddit(subreddit: string) {
    let paging: string = "";
    let pagingUrl: string = "";
    await this.store.getData("redditPagingIndex").then((data) => {
      //console.log("reddit paging: " + data)
      paging = data;
    });
    pagingUrl =
      "https://www.reddit.com/r/" + subreddit + ".json" + "?after=" + paging;
    // url_overridden_by_dest
    this.HttpClient.get(pagingUrl).subscribe(async (data: any) => {
      let redditPost: RedditPost[] = [];
      // let postTitles: string[] = [];
      let redditPagingIndex: string = "";
      let permalink: string = "";
      for (let i = 0; i < 25; i++) {
        permalink =
          "https://www.reddit.com" + data.data.children[i].data.permalink;
        //console.log(i + " " + 'this permalink: ' + permalink)
        let is_reddit_media_domain =
          data.data.children[i].data.is_reddit_media_domain;
        let crosspost_parent_list =
          data.data.children[i].data.crosspost_parent_list;
        let title: string = data.data.children[i].data.title
          .replace(/[\u200B-\u200D\uFEFF]/g, "")
          .replace(/&amp;/g, "&");
        let nsfw: boolean = data.data.children[i].data.over_18;
        let permalinkSubstr = permalink.substr(25, 40);
        let permalinkSubstrFind = permalinkSubstr.indexOf("/");
        let subReddit = permalink.substr(25, permalinkSubstrFind);
        if (
          !nsfw &&
          !title.includes("egathread") &&
          !title.includes("hread") &&
          !title.includes("iscussion")
        ) {
          //console.log("permalink: " + permalink);
          let secure_media_embed = JSON.stringify(
            data.data.children[i].data.secure_media_embed
          );
          let is_video: boolean = data.data.children[i].data.is_video;
          if (is_reddit_media_domain == true && is_video) {
            if (crosspost_parent_list == undefined) {
              let fallback_url: string =
                data.data.children[i].data.secure_media.reddit_video
                  .fallback_url;
              var dashIndex = fallback_url.indexOf("DASH_");
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  //console.log("permalink: " + permalink);
                  let replyNumber = data[1].data.children.length;
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    //console.log("permalink: " + permalink);
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "video",
                      videoURL: fallback_url,
                      audioURL:
                        fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData(
                      "redditPostPagingList",
                      redditPost
                    );
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    //console.log("permalink: " + permalink);
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "video",
                      videoURL: fallback_url,
                      audioURL:
                        fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData(
                      "redditPostPagingList",
                      redditPost
                    );
                  }
                }
              );
            } else {
              let fallback_url: string =
                crosspost_parent_list[0].secure_media.reddit_video.fallback_url;
              var dashIndex = fallback_url.indexOf("DASH_");
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  //console.log("permalink: " + permalink);
                  let replyNumber = data[1].data.children.length;
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "video",
                      videoURL: fallback_url,
                      audioURL:
                        fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData(
                      "redditPostPagingList",
                      redditPost
                    );
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "video",
                      videoURL: fallback_url,
                      audioURL:
                        fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData(
                      "redditPostPagingList",
                      redditPost
                    );
                  }
                  await this.store.setData("redditPostPagingList", redditPost);
                }
              );
            }
          } else if (is_reddit_media_domain == true && !is_video) {
            let url = data.data.children[i].data.url;
            let redditPostPermalink = permalink;
            //console.log("title: " + title);
            //console.log("permalink: " + redditPostPermalink);
            await this.HttpClient.get(permalink + ".json").subscribe(
              async (data: any) => {
                let replyNumber = data[1].data.children.length;
                if (replyNumber >= 6) {
                  let comments: string[] = [];
                  for (let index = 1; index < 6; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: "picture",
                    videoURL: null,
                    audioURL: null,
                    pictureURL: url,
                    textDescription: null,
                    shortTextDescription: null,
                    embedded: null,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                  await this.store.setData("redditPostPagingList", redditPost);
                } else if (replyNumber > 2) {
                  let comments: string[] = [];
                  for (let index = 1; index < replyNumber - 1; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: "picture",
                    videoURL: null,
                    audioURL: null,
                    pictureURL: url,
                    textDescription: null,
                    shortTextDescription: null,
                    embedded: null,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                  await this.store.setData("redditPostPagingList", redditPost);
                }
              }
            );
          } else if (secure_media_embed == "{}") {
            // text post
            let textDescription: string = data.data.children[i].data.selftext
              .replace(/[\u200B-\u200D\uFEFF]/g, "")
              .replace(/&amp;/g, "&");
            let shortTextDescription: string;
            let lastIndex: number;
            let redditPostPermalink = permalink;
            //console.log("title: " + title);
            //console.log("permalink: " + redditPostPermalink);
            if (textDescription.length >= 142) {
              lastIndex = textDescription.substr(0, 142).lastIndexOf(" ");
              shortTextDescription =
                textDescription.substr(0, lastIndex) + "<i>...see more</i>";
            }
            await this.HttpClient.get(permalink + ".json").subscribe(
              async (data: any) => {
                let replyNumber = data[1].data.children.length;
                // keep in mind the last children is not the standard structure (prob for paging info)
                if (replyNumber >= 6) {
                  let comments: string[] = [];
                  for (let index = 1; index < 6; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  let url_embed =
                    data[0].data.children[0].data.url_overridden_by_dest;
                  // ALEX TO DO: need to display the image
                  let postType: string = "embedWeb";
                  let pictureURL: string;
                  // this.getHTMLMediaByTag(url_embed);
                  if (url_embed == undefined) {
                    url_embed = null;
                    postType = "text";
                  }
                  // alert("postType: " + postType);
                  //console.log("url_embed: " + url_embed);
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: postType,
                    videoURL: null,
                    audioURL: null,
                    pictureURL: null,
                    textDescription: textDescription,
                    shortTextDescription: shortTextDescription,
                    embedded: url_embed,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                  await this.store.setData("redditPostPagingList", redditPost);
                } else if (replyNumber > 2) {
                  let comments: string[] = [];
                  for (let index = 1; index < replyNumber - 1; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  let url_embed =
                    data[0].data.children[0].data.url_overridden_by_dest;
                  let postType: string = "embedWeb";
                  let pictureURL: string;
                  // this.getHTMLMediaByTag(url_embed);
                  // await console.log("pictureURL: " + pictureURL);
                  if (url_embed == undefined) {
                    url_embed = null;
                    postType = "text";
                  }
                  // alert("postType: " + postType);
                  //console.log("url_embed: " + url_embed);
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: postType,
                    videoURL: null,
                    audioURL: null,
                    pictureURL: null,
                    textDescription: textDescription,
                    shortTextDescription: shortTextDescription,
                    embedded: url_embed,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                }
                //console.log("picture list: " + JSON.stringify(redditPost))
              }
            );
          } else if (secure_media_embed != "{}") {
            // embed posts
            let secure_media_embed =
              data.data.children[i].data.secure_media_embed.media_domain_url;
            let redditPostPermalink = permalink;
            //console.log("title: " + title);
            //console.log("permalink: " + redditPostPermalink);
            //console.log("secure_media_embed: " + JSON.stringify(secure_media_embed));
            //console.log("permalink: " + i + ": " + permalink);
            await this.HttpClient.get(permalink + ".json").subscribe(
              async (data: any) => {
                let replyNumber = data[1].data.children.length;
                // keep in mind the last children is not the standard structure (prob for paging info)
                if (replyNumber >= 6) {
                  let comments: string[] = [];
                  for (let index = 1; index < 6; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: "embed",
                    videoURL: null,
                    audioURL: null,
                    pictureURL: null,
                    textDescription: null,
                    shortTextDescription: null,
                    embedded: secure_media_embed,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                  await this.store.setData("redditPostPagingList", redditPost);
                } else if (replyNumber > 2) {
                  let comments: string[] = [];
                  for (let index = 1; index < replyNumber - 1; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: "embed",
                    videoURL: null,
                    audioURL: null,
                    pictureURL: null,
                    textDescription: null,
                    shortTextDescription: null,
                    embedded: secure_media_embed,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                }
                //console.log("picture list: " + JSON.stringify(redditPost))
              }
            );
          } else {
            console.log("all else permalink: " + i + ": " + permalink);
          }
          if (i % 24 == 0) {
            redditPagingIndex = data.data.after;
          }
        }
      }
      //console.log('redditPostPagingList: ' + redditPost)
      await this.store.setData("redditPostPagingList", redditPost);
      await this.store.setData("redditPagingIndex", redditPagingIndex);
    });
  }

  async getPreferencePagingSubReddit(subreddit: string) {
    let paging: string = "";
    let pagingUrl: string = "";
    await this.store.getData("redditPreferencePagingIndex").then((data) => {
      //console.log("reddit paging: " + data)
      paging = data;
    });
    pagingUrl =
      "https://www.reddit.com/r/" + subreddit + ".json" + "?after=" + paging;
    // url_overridden_by_dest
    this.HttpClient.get(pagingUrl).subscribe(async (data: any) => {
      let redditPost: RedditPost[] = [];
      // let postTitles: string[] = [];
      let redditPreferencePagingIndex: string = "";
      let permalink: string = "";
      for (let i = 0; i < 25; i++) {
        permalink =
          "https://www.reddit.com" + data.data.children[i].data.permalink;
        //console.log(i + " " + 'this permalink: ' + permalink)
        let is_reddit_media_domain =
          data.data.children[i].data.is_reddit_media_domain;
        let crosspost_parent_list =
          data.data.children[i].data.crosspost_parent_list;
        let title: string = data.data.children[i].data.title
          .replace(/[\u200B-\u200D\uFEFF]/g, "")
          .replace(/&amp;/g, "&");
        let nsfw: boolean = data.data.children[i].data.over_18;
        let permalinkSubstr = permalink.substr(25, 40);
        let permalinkSubstrFind = permalinkSubstr.indexOf("/");
        let subReddit = permalink.substr(25, permalinkSubstrFind);
        if (
          !nsfw &&
          !title.includes("egathread") &&
          !title.includes("hread") &&
          !title.includes("iscussion")
        ) {
          //console.log("permalink: " + permalink);
          let secure_media_embed = JSON.stringify(
            data.data.children[i].data.secure_media_embed
          );
          let is_video: boolean = data.data.children[i].data.is_video;
          if (is_reddit_media_domain == true && is_video) {
            if (crosspost_parent_list == undefined) {
              let fallback_url: string =
                data.data.children[i].data.secure_media.reddit_video
                  .fallback_url;
              var dashIndex = fallback_url.indexOf("DASH_");
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  //console.log("permalink: " + permalink);
                  let replyNumber = data[1].data.children.length;
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    //console.log("permalink: " + permalink);
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "video",
                      videoURL: fallback_url,
                      audioURL:
                        fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData(
                      "redditPreferencePostPagingList",
                      redditPost
                    );
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    //console.log("permalink: " + permalink);
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "video",
                      videoURL: fallback_url,
                      audioURL:
                        fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData(
                      "redditPreferencePostPagingList",
                      redditPost
                    );
                  }
                }
              );
            } else {
              let fallback_url: string =
                crosspost_parent_list[0].secure_media.reddit_video.fallback_url;
              var dashIndex = fallback_url.indexOf("DASH_");
              let redditPostPermalink = permalink;
              //console.log("title: " + title);
              //console.log("permalink: " + redditPostPermalink);
              await this.HttpClient.get(permalink + ".json").subscribe(
                async (data: any) => {
                  //console.log("permalink: " + permalink);
                  let replyNumber = data[1].data.children.length;
                  if (replyNumber >= 6) {
                    let comments: string[] = [];
                    for (let index = 1; index < 6; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "video",
                      videoURL: fallback_url,
                      audioURL:
                        fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData(
                      "redditPreferencePostPagingList",
                      redditPost
                    );
                  } else if (replyNumber > 2) {
                    let comments: string[] = [];
                    for (let index = 1; index < replyNumber - 1; index++) {
                      let commentToAdd = data[1].data.children[index].data.body
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      let author = data[1].data.children[index].data.author
                        .replace(/[\u200B-\u200D\uFEFF]/g, "")
                        .replace(/&amp;/g, "&");
                      if (
                        !(
                          commentToAdd == "[removed]" ||
                          commentToAdd == "[deleted]" ||
                          commentToAdd.length > 400
                        )
                      ) {
                        comments.push("<u>" + author + "</u>: " + commentToAdd);
                      }
                    }
                    redditPost.push({
                      title: title,
                      permalink: redditPostPermalink,
                      postType: "video",
                      videoURL: fallback_url,
                      audioURL:
                        fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
                      pictureURL: null,
                      textDescription: null,
                      shortTextDescription: null,
                      embedded: null,
                      comments: comments,
                      authors: null,
                      subReddit: subReddit,
                    });
                    await this.store.setData(
                      "redditPreferencePostPagingList",
                      redditPost
                    );
                  }
                  await this.store.setData("redditPreferencePostPagingList", redditPost);
                }
              );
            }
          } else if (is_reddit_media_domain == true && !is_video) {
            let url = data.data.children[i].data.url;
            let redditPostPermalink = permalink;
            //console.log("title: " + title);
            //console.log("permalink: " + redditPostPermalink);
            await this.HttpClient.get(permalink + ".json").subscribe(
              async (data: any) => {
                let replyNumber = data[1].data.children.length;
                if (replyNumber >= 6) {
                  let comments: string[] = [];
                  for (let index = 1; index < 6; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: "picture",
                    videoURL: null,
                    audioURL: null,
                    pictureURL: url,
                    textDescription: null,
                    shortTextDescription: null,
                    embedded: null,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                  await this.store.setData("redditPreferencePostPagingList", redditPost);
                } else if (replyNumber > 2) {
                  let comments: string[] = [];
                  for (let index = 1; index < replyNumber - 1; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: "picture",
                    videoURL: null,
                    audioURL: null,
                    pictureURL: url,
                    textDescription: null,
                    shortTextDescription: null,
                    embedded: null,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                  await this.store.setData("redditPreferencePostPagingList", redditPost);
                }
              }
            );
          } else if (secure_media_embed == "{}") {
            // text post
            let textDescription: string = data.data.children[i].data.selftext
              .replace(/[\u200B-\u200D\uFEFF]/g, "")
              .replace(/&amp;/g, "&");
            let shortTextDescription: string;
            let lastIndex: number;
            let redditPostPermalink = permalink;
            //console.log("title: " + title);
            //console.log("permalink: " + redditPostPermalink);
            if (textDescription.length >= 142) {
              lastIndex = textDescription.substr(0, 142).lastIndexOf(" ");
              shortTextDescription =
                textDescription.substr(0, lastIndex) + "<i>...see more</i>";
            }
            await this.HttpClient.get(permalink + ".json").subscribe(
              async (data: any) => {
                let replyNumber = data[1].data.children.length;
                // keep in mind the last children is not the standard structure (prob for paging info)
                if (replyNumber >= 6) {
                  let comments: string[] = [];
                  for (let index = 1; index < 6; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  let url_embed =
                    data[0].data.children[0].data.url_overridden_by_dest;
                  // ALEX TO DO: need to display the image
                  let postType: string = "embedWeb";
                  let pictureURL: string;
                  // this.getHTMLMediaByTag(url_embed);
                  if (url_embed == undefined) {
                    url_embed = null;
                    postType = "text";
                  }
                  // alert("postType: " + postType);
                  //console.log("url_embed: " + url_embed);
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: postType,
                    videoURL: null,
                    audioURL: null,
                    pictureURL: null,
                    textDescription: textDescription,
                    shortTextDescription: shortTextDescription,
                    embedded: url_embed,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                  await this.store.setData("redditPreferencePostPagingList", redditPost);
                } else if (replyNumber > 2) {
                  let comments: string[] = [];
                  for (let index = 1; index < replyNumber - 1; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  let url_embed =
                    data[0].data.children[0].data.url_overridden_by_dest;
                  let postType: string = "embedWeb";
                  let pictureURL: string;
                  // this.getHTMLMediaByTag(url_embed);
                  // await console.log("pictureURL: " + pictureURL);
                  if (url_embed == undefined) {
                    url_embed = null;
                    postType = "text";
                  }
                  // alert("postType: " + postType);
                  //console.log("url_embed: " + url_embed);
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: postType,
                    videoURL: null,
                    audioURL: null,
                    pictureURL: null,
                    textDescription: textDescription,
                    shortTextDescription: shortTextDescription,
                    embedded: url_embed,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                }
                //console.log("picture list: " + JSON.stringify(redditPost))
              }
            );
          } else if (secure_media_embed != "{}") {
            // embed posts
            let secure_media_embed =
              data.data.children[i].data.secure_media_embed.media_domain_url;
            let redditPostPermalink = permalink;
            //console.log("title: " + title);
            //console.log("permalink: " + redditPostPermalink);
            //console.log("secure_media_embed: " + JSON.stringify(secure_media_embed));
            //console.log("permalink: " + i + ": " + permalink);
            await this.HttpClient.get(permalink + ".json").subscribe(
              async (data: any) => {
                let replyNumber = data[1].data.children.length;
                // keep in mind the last children is not the standard structure (prob for paging info)
                if (replyNumber >= 6) {
                  let comments: string[] = [];
                  for (let index = 1; index < 6; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: "embed",
                    videoURL: null,
                    audioURL: null,
                    pictureURL: null,
                    textDescription: null,
                    shortTextDescription: null,
                    embedded: secure_media_embed,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                  await this.store.setData("redditPreferencePostPagingList", redditPost);
                } else if (replyNumber > 2) {
                  let comments: string[] = [];
                  for (let index = 1; index < replyNumber - 1; index++) {
                    let commentToAdd = data[1].data.children[index].data.body
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    let author = data[1].data.children[index].data.author
                      .replace(/[\u200B-\u200D\uFEFF]/g, "")
                      .replace(/&amp;/g, "&");
                    if (
                      !(
                        commentToAdd == "[removed]" ||
                        commentToAdd == "[deleted]" ||
                        commentToAdd.length > 400
                      )
                    ) {
                      comments.push("<u>" + author + "</u>: " + commentToAdd);
                    }
                  }
                  redditPost.push({
                    title: title,
                    permalink: redditPostPermalink,
                    postType: "embed",
                    videoURL: null,
                    audioURL: null,
                    pictureURL: null,
                    textDescription: null,
                    shortTextDescription: null,
                    embedded: secure_media_embed,
                    comments: comments,
                    authors: null,
                    subReddit: subReddit,
                  });
                }
                //console.log("picture list: " + JSON.stringify(redditPost))
              }
            );
          } else {
            console.log("all else permalink: " + i + ": " + permalink);
          }
          if (i % 24 == 0) {
            redditPreferencePagingIndex = data.data.after;
          }
        }
      }
      //console.log('redditPreferencePostPagingList: ' + redditPost)
      await this.store.setData("redditPreferencePostPagingList", redditPost);
      await this.store.setData("redditPreferencePagingIndex", redditPreferencePagingIndex);
    });
  }

  async getPagingSubReddit2(subreddit: string) {
    let paging: string = "";
    let pagingUrl: string = "";
    await this.store.getData("redditPagingIndex").then((data) => {
      //console.log("reddit paging: " + data)
      paging = data;
    });
    pagingUrl =
      "https://www.reddit.com/r/" + subreddit + ".json" + "?after=" + paging;
    console.log("paging url: " + pagingUrl);
    // url_overridden_by_dest
  }

  async getComment(permalink: string) {
    let commentsToAdd: string[] = [];
    //console.log("permalink: " + permalink)
    await this.HttpClient.get(permalink + ".json").subscribe(
      async (data: any) => {
        let replyNumber = data[1].data.children.length;
        // keep in mind the last children is not the standard structure (prob for paging info)
        if (replyNumber >= 6) {
          for (let index = 1; index < 6; index++) {
            let commentToAdd = data[1].data.children[index].data.body
              .replace(/[\u200B-\u200D\uFEFF]/g, "")
              .replace(/&amp;/g, "&");
            let author = data[1].data.children[index].data.author
              .replace(/[\u200B-\u200D\uFEFF]/g, "")
              .replace(/&amp;/g, "&");
            if (
              !(
                commentToAdd == "[removed]" ||
                commentToAdd == "[deleted]" ||
                commentToAdd.length > 400
              )
            ) {
              await commentsToAdd.push(commentToAdd);
            }
          }
        } else if (replyNumber > 2) {
          for (let index = 1; index < replyNumber - 1; index++) {
            let commentToAdd = data[1].data.children[index].data.body
              .replace(/[\u200B-\u200D\uFEFF]/g, "")
              .replace(/&amp;/g, "&");
            let author = data[1].data.children[index].data.author
              .replace(/[\u200B-\u200D\uFEFF]/g, "")
              .replace(/&amp;/g, "&");
            if (
              !(
                commentToAdd == "[removed]" ||
                commentToAdd == "[deleted]" ||
                commentToAdd.length > 400
              )
            ) {
              await commentsToAdd.push(commentToAdd);
            }
          }
        }
        this.comments = commentsToAdd;
        //console.log("picture list: " + JSON.stringify(redditPost))
      }
    );
  }

  clearData() {
    this.store.setData("redditPost", null);
    this.store.setData("redditPagingIndex", null);
  }

  getHTMLMediaByTag(url: string) {
    let JPG: string = "";
    return this.HttpClient.get(url, { responseType: "text" }).subscribe(
      (data: string) => {
        if (data.includes('<img src="https://')) {
          let imgTagWhole = data.substring(data.indexOf('<img src="') + 10);
          let JpgToParse = imgTagWhole.substring(
            0,
            imgTagWhole.indexOf(".jpg") + 4
          );
          //console.log("JpgToParse: " + JpgToParse);

          if (
            JpgToParse.includes(".jpg") &&
            JpgToParse.includes("https://") &&
            JpgToParse.length < 300
          ) {
            JPG = JpgToParse.substring(0, JpgToParse.indexOf(".jpg") + 4);
            console.log("JPG: " + JPG);

            return JPG;
          }
        }
      }
    );
  }

  // getHTMLMediaByTag(url:string):Observable<any>{
  //   let JPG: string = "";
  //   let pictureCall = this.HttpClient.get(url, {responseType: "text"});
  //   return pictureCall;
  // }

  // need to call
  // await this.store.getData('redditPagingIndex').then((data) => {
  //   //console.log("reddit paging: " + data)
  //   paging = data;
  // })
  // async getInitSubReddit3(subreddit:string, paging:string){
  //   console.log('paging: ' + paging)
  //   let pagingUrl:string = "";
  //   if(paging != null && paging != ''){
  //     pagingUrl = 'https://www.reddit.com/r/' + subreddit + '.json' + '?after=' + paging;
  //     console.log("paging url: " + pagingUrl);
  //     console.log('not null paging: ' + paging)
  //   }else{
  //     pagingUrl = 'https://www.reddit.com/r/' + subreddit + '.json';
  //     console.log('null paging: ' + paging);
  //   }
  //   await this.HttpClient.get(pagingUrl).subscribe(
  //     async (data:any) => {
  //       let redditPost: RedditPost[] = []; let redditPagingIndex: string = "";
  //       for (let i = 0; i < 25; i++) {
  //         if(data != undefined){
  //           let permalink:string = "https://www.reddit.com" + data.data.children[i].data.permalink;
  //           let is_reddit_media_domain:boolean = data.data.children[i].data.is_reddit_media_domain;
  //           let crosspost_parent_list:any = data.data.children[i].data.crosspost_parent_list;
  //           let title:string = data.data.children[i].data.title.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //           let secure_media_embed:string = JSON.stringify(data.data.children[i].data.secure_media_embed);
  //           let is_video:boolean = data.data.children[i].data.is_video;
  //           //console.log("title: " + title);
  //           //console.log("permalink: " + permalink);
  //           if( is_reddit_media_domain == true && is_video){
  //             if(crosspost_parent_list == undefined){
  //               let fallback_url:string = data.data.children[i].data.secure_media.reddit_video.fallback_url;
  //               let dashIndex:number = fallback_url.indexOf("DASH_");
  //               await this.HttpClient.get(permalink + '.json').subscribe(
  //                 async (data:any) => {
  //                   let replyNumber:number = data[1].data.children.length
  //                   if( replyNumber >= 6 ){
  //                     let comments : string[] = [];
  //                     for (let index = 1; index < 6; index++) {
  //                       let commentToAdd:string = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );;
  //                       let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                       if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                         comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                       }
  //                     }
  //                   redditPost.push({
  //                     "title" : title,
  //                     "permalink" : null,
  //                     "postType" : "video",
  //                     "videoURL" : fallback_url,
  //                     "audioURL" : fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
  //                     "pictureURL" : null,
  //                     "textDescription" : null,
  //                     "shortTextDescription" : null,
  //                     "embedded" : null,
  //                     "comments" : comments,
  //                     "authors" : null,
  //                   })
  //                     await this.store.setData('redditPost', redditPost);
  //                   }
  //                   else if(replyNumber > 2){
  //                     let comments: string[] = []
  //                     for (let index = 1; index < replyNumber-1; index++) {
  //                       let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                       let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                       if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                         comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                       }
  //                     }
  //                   redditPost.push({
  //                     "title" : title,
  //                     "permalink" : null,
  //                     "postType" : "video",
  //                     "videoURL" : fallback_url,
  //                     "audioURL" : fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
  //                     "pictureURL" : null,
  //                     "textDescription" : null,
  //                     "shortTextDescription" : null,
  //                     "embedded" : null,
  //                     "comments" : comments,
  //                     "authors" : null,
  //                   })
  //                     await this.store.setData('redditPost', redditPost);
  //                   }
  //                 })
  //             }else{
  //               let fallback_url:string = crosspost_parent_list[0].secure_media.reddit_video.fallback_url;
  //               // postTitles[i]="[Crosspost] "+title;
  //               var dashIndex = fallback_url.indexOf("DASH_");
  //               await this.HttpClient.get(permalink + '.json').subscribe(
  //                 async (data:any) => {
  //                   let replyNumber = data[1].data.children.length
  //                   if( replyNumber >= 6 ){
  //                     let comments : string[] = [];
  //                     for (let index = 1; index < 6; index++) {
  //                       let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                       let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                       if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                         comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                       }
  //                     }
  //                   redditPost.push({
  //                     "title" : title,
  //                     "permalink" : null,
  //                     "postType" : "video",
  //                     "videoURL" : fallback_url,
  //                     "audioURL" : fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
  //                     "pictureURL" : null,
  //                     "textDescription" : null,
  //                     "shortTextDescription" : null,
  //                     "embedded" : null,
  //                     "comments" : comments,
  //                     "authors" : null,
  //                   })
  //                     await this.store.setData('redditPost', redditPost);
  //                   }
  //                   else if(replyNumber > 2){
  //                     let comments: string[] = []
  //                     for (let index = 1; index < replyNumber-1; index++) {
  //                       let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                       let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                       if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                         comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                       }
  //                     }
  //                   redditPost.push({
  //                     "title" : title,
  //                     "permalink" : null,
  //                     "postType" : "video",
  //                     "videoURL" : fallback_url,
  //                     "audioURL" : fallback_url.slice(0, dashIndex) + "DASH_audio.mp4",
  //                     "pictureURL" : null,
  //                     "textDescription" : null,
  //                     "shortTextDescription" : null,
  //                     "embedded" : null,
  //                     "comments" : comments,
  //                     "authors" : null,
  //                   })
  //                     await this.store.setData('redditPost', redditPost);
  //                   }
  //                   await this.store.setData('redditPost', redditPost);
  //                   //console.log("redditPost: " + JSON.stringify(redditPost))
  //                 })
  //             }
  //           }
  //           else if(is_reddit_media_domain == true && !is_video){
  //             let url = data.data.children[i].data.url;
  //             await this.HttpClient.get(permalink + '.json').subscribe(
  //               async (data:any) => {
  //                 let replyNumber = data[1].data.children.length
  //                 if( replyNumber >= 6 ){
  //                   let comments : string[] = [];
  //                   for (let index = 1; index < 6; index++) {
  //                     let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                     let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                     if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                       comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                     }
  //                   }
  //                   redditPost.push({
  //                     "title" : title,
  //                     "permalink" : null,
  //                     "postType" : "picture",
  //                     "videoURL": null,
  //                     "audioURL": null,
  //                     "pictureURL" : url,
  //                     "textDescription" : null,
  //                     "shortTextDescription" : null,
  //                     "embedded" : null,
  //                     "comments" : comments,
  //                     "authors" : null,
  //                   });
  //                   await this.store.setData('redditPost', redditPost);
  //                 }
  //                 else if(replyNumber > 2){
  //                   let comments: string[] = []
  //                   for (let index = 1; index < replyNumber-1; index++) {
  //                     let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                     let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                     if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                       comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                     }
  //                   }
  //                   redditPost.push({
  //                     "title" : title,
  //                     "permalink" : null,
  //                     "postType" : "picture",
  //                     "videoURL": null,
  //                     "audioURL": null,
  //                     "pictureURL" : url,
  //                     "textDescription" : null,
  //                     "shortTextDescription" : null,
  //                     "embedded" : null,
  //                     "comments" : comments,
  //                     "authors" : null,
  //                   });
  //                   await this.store.setData('redditPost', redditPost);
  //                 }
  //               })
  //           }
  //           else if( secure_media_embed == "{}"){
  //             // text post
  //             // let textDescription:string = data.data.children[i].data.selftext;
  //             // let textDescription:string = data.data.children[i].data.selftext;
  //             let textDescription:string = data.data.children[i].data.selftext.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );;
  //             let shortTextDescription:string;
  //             let lastIndex:number;
  //             if(textDescription.length >= 142 ){
  //               lastIndex = textDescription.substr(0, 142).lastIndexOf(' ');
  //               shortTextDescription = textDescription.substr(0, lastIndex) + '<i>...see more</i>';
  //             }
  //             await this.HttpClient.get(permalink + '.json').subscribe(
  //               async (data:any) => {
  //               let replyNumber = data[1].data.children.length
  //               // keep in mind the last children is not the standard structure (prob for paging info)
  //               if( replyNumber >= 6 ){
  //                 let comments : string[] = [];
  //                 for (let index = 1; index < 6; index++) {
  //                   let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                   let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                   if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                     comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                   }
  //                 }
  //                 let url_embed = data[0].data.children[0].data.url_overridden_by_dest;
  //                 // ALEX TO DO: need to display the image
  //                 let postType: string = "embedWeb"; let pictureURL: string;
  //                 // this.getHTMLMediaByTag(url_embed);
  //                 if (url_embed == undefined){
  //                   url_embed = null;
  //                   postType = "text";
  //                 }
  //                 // alert("postType: " + postType);
  //                 //console.log("url_embed: " + url_embed);
  //                 redditPost.push({
  //                   "title" : title,
  //                   "permalink" : null,
  //                   "postType" : postType,
  //                   "videoURL": null,
  //                   "audioURL": null,
  //                   "pictureURL" : null,
  //                   "textDescription" : textDescription,
  //                   "shortTextDescription" : shortTextDescription,
  //                   "embedded" : url_embed,
  //                   "comments" : comments,
  //                   "authors" : null,
  //                 })
  //                 await this.store.setData('redditPost', redditPost);
  //               }
  //               else if(replyNumber > 2){
  //                 let comments: string[] = []
  //                 for (let index = 1; index < replyNumber-1; index++) {
  //                   let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                   let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                   if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                     comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                   }
  //                 }
  //                 let url_embed = data[0].data.children[0].data.url_overridden_by_dest;
  //                 let postType: string = "embedWeb"; let pictureURL: string;
  //                 // this.getHTMLMediaByTag(url_embed);
  //                 await console.log("pictureURL: " + pictureURL)
  //                 if (url_embed == undefined){
  //                   url_embed = null;
  //                   postType = "text";
  //                 }
  //                 // alert("postType: " + postType);
  //                 //console.log("url_embed: " + url_embed);
  //                 redditPost.push({
  //                   "title" : title,
  //                   "permalink" : null,
  //                   "postType" : postType,
  //                   "videoURL": null,
  //                   "audioURL": null,
  //                   "pictureURL" : null,
  //                   "textDescription" : textDescription,
  //                   "shortTextDescription" : shortTextDescription,
  //                   "embedded" : url_embed,
  //                   "comments" : comments,
  //                   "authors" : null,
  //                 })
  //               }
  //               //console.log("picture list: " + JSON.stringify(redditPost))
  //             })

  //           }
  //           else if( secure_media_embed != "{}"){
  //             // embed posts
  //             let secure_media_embed = data.data.children[i].data.secure_media_embed.media_domain_url;
  //             //console.log("secure_media_embed: " + JSON.stringify(secure_media_embed));
  //             //console.log("permalink: " + i + ": " + permalink);
  //             await this.HttpClient.get(permalink + '.json').subscribe(
  //               async (data:any) => {
  //               let replyNumber = data[1].data.children.length
  //               // keep in mind the last children is not the standard structure (prob for paging info)
  //               if( replyNumber >= 6 ){
  //                 let comments : string[] = [];
  //                 for (let index = 1; index < 6; index++) {
  //                   let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                   let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                   if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                     comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                   }
  //                 }
  //                 redditPost.push({
  //                   "title" : title,
  //                   "permalink" : null,
  //                   "postType" : "embed",
  //                   "videoURL": null,
  //                   "audioURL": null,
  //                   "pictureURL" : null,
  //                   "textDescription" : null,
  //                   "shortTextDescription" : null,
  //                   "embedded" : secure_media_embed,
  //                   "comments" : comments,
  //                   "authors" : null,
  //                 })
  //                 await this.store.setData('redditPost', redditPost);
  //               }
  //               else if(replyNumber > 2){
  //                 let comments: string[] = []
  //                 for (let index = 1; index < replyNumber-1; index++) {
  //                   let commentToAdd = data[1].data.children[index].data.body.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                   let author = data[1].data.children[index].data.author.replace(/[\u200B-\u200D\uFEFF]/g, '').replace( /&amp;/g, '&' );
  //                   if(!(commentToAdd == '[removed]' || commentToAdd == '[deleted]' || commentToAdd.length > 400)){
  //                     comments.push('<u>' + author + '</u>: '+commentToAdd)
  //                   }
  //                 }
  //                 redditPost.push({
  //                   "title" : title,
  //                   "permalink" : null,
  //                   "postType" : "embed",
  //                   "videoURL": null,
  //                   "audioURL": null,
  //                   "pictureURL" : null,
  //                   "textDescription" : null,
  //                   "shortTextDescription" : null,
  //                   "embedded" : secure_media_embed,
  //                   "comments" : comments,
  //                   "authors" : null,
  //                 })
  //               }
  //               //console.log("picture list: " + JSON.stringify(redditPost))
  //             })

  //           }
  //           else{
  //             console.log("all else permalink: " + i + ": " + permalink)
  //           }
  //           if( i % 24 == 0){
  //             redditPagingIndex = data.data.after;
  //           }
  //         }
  //       }
  //       await this.store.setData('redditPost', redditPost);
  //       await this.store.setData(subreddit, redditPagingIndex);
  //     }
  //   )
  // }
}
