import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { CommonService } from "../common/common.service";

const LNG_KEY = "SELECTED_LANGUAGE";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  selected = "";

  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private commonService: CommonService
  ) {}

  setDefaultLanguage() {
    let language = this.translate.getBrowserCultureLang();
    // this.translate.setDefaultLang(language);
    this.translate.setDefaultLang("en");

    // this.commonService.getData(LNG_KEY).then(val => {
    //   if(val){
    //     this.setLanguage(val);
    //     this.selected = val;
    //   }
    // })
  }

  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    this.commonService.setData(LNG_KEY, lng);
  }

  getLanguage() {
    return [{ text: "English", value: "en" }];
  }
}
