import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { IonicStorageModule } from "@ionic/storage";
import { HTTP } from "@ionic-native/http/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { LinkyModule } from "ngx-linky";
import { NativeStorage } from "@ionic-native/native-storage/ngx";
import { SafariViewController } from "@ionic-native/safari-view-controller/ngx";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { Network } from "@ionic-native/network/ngx";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Device } from "@ionic-native/device/ngx";
import { LaunchReview } from '@ionic-native/launch-review/ngx';
// import { Screenshot } from '@ionic-native/screenshot/ngx';
// import { LocalNotifications } from '@ionic-native/local-notifications/ngx';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from "@aws-amplify/ui-angular";
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from "../aws-exports";
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

/* Configure Amplify resources */
Amplify.configure(awsconfig);

// const config: SocketIoConfig = { url: 'http://localhost:3000', options: {}};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      animated: true,
    }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    LinkyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AmplifyAngularModule,
    AmplifyUIAngularModule,
    // SocketIoModule.forRoot(config)
    // TabsPageModule,
    // DashboardPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HTTP,
    InAppBrowser,
    NativeStorage,
    SafariViewController,
    SocialSharing,
    Network,
    AmplifyService,
    AppVersion,
    Device,
    LaunchReview,
    // Screenshot
    // LocalNotifications,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
