import { Injectable } from "@angular/core";
import { StorageService } from "../common/storage.service";
import { CommonService } from "../common/common.service";
import { HttpClient } from "@angular/common/http";
import {
  RedditObject,
  RedditPictureList,
  RedditPost,
  RedditTextObject,
} from "../../models/index";
import { HTTP } from "@ionic-native/http/ngx";
import { map, tap, filter, finalize } from "rxjs/operators";
import { from } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TimeService {
  comments: string[] = [];

  constructor(
    public store: StorageService,
    public HttpClient: HttpClient,
    public commonService: CommonService,
    private http: HTTP
  ) {
    this.http.setDataSerializer("json");
    // alert('reddit service');
  }

  async getTime(timezone: string) {
    await this.HttpClient.get(
      "http://worldtimeapi.org/api/timezone/" + timezone
    ).subscribe((data) => {
      alert(data);
    });
  }

  async getUTCTime() {
    //console.log('test http')
    let url = "https://worldtimeapi.org/api/timezone/Etc/UTC";
    await this.HttpClient.get(url).subscribe(async (data: any) => {
      let utc = data.utc_datetime.substring(0, 10);
      await this.commonService.setData("utc", utc);
    });

    // let nativeCall = this.http.get(url, {}, headerDic);
    // return from(nativeCall).pipe(
    //   map((res) =>  JSON.parse(res.data)),
    //     tap((data) => {
    //       console.log('test http2')
    //       console.log('Native Http Data', data);
    //       return;
    //     },
    //     (err: any) => {
    //       console.log(err);
    //     })
    // )
  }

  // async getUTCTime(){
  //   let test;
  //   await this.HttpClient.get('http://worldtimeapi.org/api/timezone/Etc/UTC').subscribe((data:any) => {
  //       test = data.utc_datetime;
  //       this.commonService.question = data.utc_datetime;
  //   })
  //   alert(test);
  // }
}
