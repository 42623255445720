import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { Platform, LoadingController } from "@ionic/angular";
import { Subject } from "rxjs";
import { loadingText } from "../../staticData/loadingText";
import { appText } from "../../staticData/appText";
import { NativeStorage } from "@ionic-native/native-storage/ngx";
import {
  SafariViewController,
  SafariViewControllerOptions,
} from "@ionic-native/safari-view-controller/ngx";
import {
  InAppBrowser,
  InAppBrowserEvent,
} from "@ionic-native/in-app-browser/ngx";
import { Network } from "@ionic-native/network/ngx";
import { BehaviorSubject, Observable } from "rxjs";
import { AlertController } from "@ionic/angular";
import { APIService } from "../../API.service";
import { UserInfoList, UserInfo } from "../../models/index";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { TranslateService } from "@ngx-translate/core";
// import { LocalNotifications } from '@ionic-native/local-notifications/ngx';

export enum ConnectionStatus {
  Online,
  Offline,
}

@Injectable({
  providedIn: "root",
})
export class CommonService {
  loadingProgress: any;
  private userSubject = new Subject<any>();
  private eventSubject = new Subject<any>();
  private authenticatedSubject = new Subject<any>();
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(
    ConnectionStatus.Offline
  );
  public hideToolbars: boolean = false;
  public MBTIType: string;
  // public appVersion: string = "";
  public passwordFieldFlag: boolean = false;
  public authenticated: boolean = false;
  private dailyPollQuestion: string;
  private targetDate: Date;
  private targetDateString: string;

  // This needs to get from API
  // public answer1: string = 'A';
  // public answer2: string = 'B';
  // public question: string = 'Would you rather <br/>(A) get $650,000 or <br/>(B) get unlimted coffee for life?'
  public answer1: string;
  public answer2: string;
  public question: string;
  public totalVote: number = 0;
  public answer1Vote: number = 0;
  public answer2Vote: number = 0;
  public answer1Percent: number = 0;
  public answer2Percent: number = 0;
  public answer1PercentChart: number = 50;
  public answer2PercentChart: number = 100;
  public pollAnswered: boolean = false;
  public username: string;
  private utc: string;
  private pushNotificationEnabled: boolean;

  constructor(
    private storage: Storage,
    private loadingCtrl: LoadingController,
    private nativeStorage: NativeStorage,
    private safariViewController: SafariViewController,
    private inAppBrowser: InAppBrowser,
    private network: Network,
    private platform: Platform,
    private alertController: AlertController,
    private apiService: APIService,
    private socialSharing: SocialSharing,
    private translateService: TranslateService,
    // private localNotifications: LocalNotifications
  ) {
    this.platform.ready().then(() => {
      this.getData("question").then((question) => {
        this.question = question;
      });
      this.getData("answer1").then((answer1) => {
        this.answer1 = answer1;
      });
      this.getData("answer2").then((answer2) => {
        this.answer2 = answer2;
      });
      this.getData("awsUserInfo").then((data) => {
        if (data == undefined && data == null) {
          let random = "UNREGISTER" + Math.random().toString().substring(2, 6);
          this.username = random;
        } else {
          this.username = data.username;
          // alert('username: ' + data)
        }
      });
      this.getData("awsUserInfo").then((data: UserInfo) => {
        this.MBTIType = data.mbti;
        // alert('user is: ' + this.MBTIType)
      });
      this.getData("MBTI_Type").then((data) => {
        this.MBTIType = data;
        // alert('user is: ' + this.MBTIType)
      });

      this.initializeNetworkEvents();
      let status =
        this.network.type !== "none"
          ? ConnectionStatus.Online
          : ConnectionStatus.Offline;
      this.status.next(status);
    });
  }
  getTextField(parameter: string) {
    const value = (document.getElementById(parameter) as HTMLInputElement)
      .value;
    //console.log('value: ' + value)
    return value;
  }

  containsSpecialCharacters(stringToCheck: string) {
    var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
    if (pattern.test(stringToCheck)) {
      return false;
    }
    return true;
  }

  returnHTMLInputElement(parameter: string): HTMLInputElement {
    const value = document.getElementById(parameter) as HTMLInputElement;
    return value;
  }

  getRadioButton(parameter: string) {
    const value = (document.getElementById(parameter) as HTMLInputElement)
      .checked;
    return value;
  }
  async presentPromptQuestion(
    title: string,
    inputs: Array<any>,
    buttons: Array<any>
  ) {
    const alert = await this.alertController.create({
      cssClass: "promptInput",
      header: title,
      inputs: inputs,
      buttons: buttons,
      backdropDismiss: false,
    });

    await alert.present();
  }

  // getAWSUserId(usernameFromApp: string) {
  //   return this.apiService.ListUsers({
  //     username: {
  //       eq: usernameFromApp,
  //     },
  //   });
  // }

  getSingleAWSUserId(awsUserId: string) {
    return this.apiService.GetUser(
      awsUserId
    );
  }

  async updateUserAPIPushNotification(id: string, updateAPI?: boolean) {
    this.getSingleAWSUserId(id).then(async (data: any) => {
      let userInfoVar: UserInfo;
      userInfoVar = data;
      //console.log('data: ' + JSON.stringify(data))
      let pushNotification: boolean;
      if (this.pushNotificationEnabled) {
        pushNotification = true;
      } else {
        pushNotification = false;
      }
      userInfoVar.pushNotification = pushNotification;
      if (updateAPI == undefined && updateAPI != true) {
        await this.apiService.UpdateUser({
          id: userInfoVar.id,
          pushNotification: userInfoVar.pushNotification,
        });
      }
      this.setData("awsUserInfo", userInfoVar);
    });
  }
  // getAWSUserId(usernameFromApp: string){
  //   this.apiService.ListUsers({
  //     username: {
  //       eq: usernameFromApp
  //     }
  //   }).then((item) => {
  //     console.log('item: ' + JSON.stringify(item));
  //     return item;
  //   });
  // }

  // async scheduleLocalNotification(dailyPollQuestion: string) {
  //   await this.getData('lastNotificationDate').then(async (lastNotificationDateData) => {
  //     if (lastNotificationDateData != undefined && lastNotificationDateData != null) {
  //       await this.localNotifications.cancel(lastNotificationDateData);
  //     }
  //   })
  //   await this.checkPushNotificationEnabled();
  //   let targetDateNum: number = Number(this.targetDateString.replace(/-/g, ''));
  //   let targetDate = new Date(this.targetDate.getTime());
  //   // let testTargetDate = Math.floor(Math.random() * (1000 - 1 + 1) + 1);
  //   // let currnetDate = new Date();
  //   // console.log('current date: ' + currnetDate);
  //   // console.log(' target date: ' + targetDate);
  //   // console.log("scheduling push notification")
  //   if (this.pushNotificationEnabled) {
  //     await this.localNotifications.schedule({
  //       // id: testTargetDate,
  //       id: targetDateNum,
  //       text: "[DAILY POLL]: " + dailyPollQuestion,
  //       trigger: { at: targetDate },
  //       led: '0ec6fe'
  //     });
  //   }
  //   await this.setData("lastNotificationDate", targetDateNum)
  // }
  // async getFuturePromptLocalNotification(): Promise<string> {
  //   await this.getData("utc").then(async (utc) => {
  //     this.utc = utc;
  //     // console.log('utc: ' + this.utc)
  //   });
  //   // 4 days laters after current utc time
  //   this.targetDate = new Date(new Date().getTime() + (4000 * 60 * 60 * 24))
  //   // this.targetDate = new Date(new Date().getTime() + (10000));
  //   this.targetDateString = this.targetDate.toISOString().substr(0, 10);
  //   await this.apiService.GetDailypoll(this.targetDateString).then((data) => {
  //     // alert('data: '+ JSON.stringify(data))
  //     this.dailyPollQuestion = data.question;
  //     // console.log("dailyPollQuestion: " + this.dailyPollQuestion)
  //   })
  //   // alert("this.dailyPollQuestion: " + this.dailyPollQuestion)
  //   return this.dailyPollQuestion;
  // }
  shareMBTIAction(MBTI: string) {
    let url: string = "https://www.boredyet.me/tabs/tab2";
    let title = MBTI + ' ---- '
    let text: string = this.translateService.instant(MBTI + '.TITLE1P1');
    text = title + text.substr(0, 320) + '...see more';
    this.socialSharing
      .share(text, MBTI, null, url)
      .then(() => {
        // Success!
      })
      .catch(() => {
        // Error!
      });
  }

  async checkPushNotificationEnabled(): Promise<boolean> {
    await this.getData('pushNotificationEnabled').then((pushNotificationEnabledData) => {
      if (pushNotificationEnabledData != undefined && pushNotificationEnabledData != null) {
        this.pushNotificationEnabled = pushNotificationEnabledData;
        // alert("this.pushNotificationEnabled 1: " + this.pushNotificationEnabled)
      }
    });
    return this.pushNotificationEnabled;
  }


  async presentAlertConfirm(header: string, message: string) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: header,
      message: message,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => { },
        },
        {
          text: "Confirm",
          handler: () => { },
        },
      ],
    });

    await alert.present();
  }

  getPollAnswered() {
    return this.pollAnswered;
  }
  async updateMBTIType(MBTIType: string) {
    console.log("update mbti: " + MBTIType)
    // consider using a local uesr object to store user info locally for registered users
    this.setData("MBTI_Type", MBTIType);
    // await this.getFuturePromptLocalNotification().then((dailyPollQuestion) => {
    //   this.dailyPollQuestion = dailyPollQuestion;
    // });
    // await this.scheduleLocalNotification(this.dailyPollQuestion);
    this.MBTIType = MBTIType;
  }
  submitAnswer1() {
    this.totalVote += 1;
    this.answer1Vote += 1;
    this.pollAnswered = true;
    this.answer1Percent = (this.answer1Vote / this.totalVote) * 100;
    this.answer2Percent = (this.answer2Vote / this.totalVote) * 100;
    this.answer1PercentChart = this.answer1Percent;
    // return this.answer1Percent; // 0.something
  }
  submitAnswer2() {
    this.totalVote += 1;
    this.answer2Vote += 1;
    this.pollAnswered = true;
    this.answer1Percent = (this.answer1Vote / this.totalVote) * 100;
    this.answer2Percent = (this.answer2Vote / this.totalVote) * 100;
    this.answer1PercentChart = this.answer1Percent;
    // return this.answer2Percent;
  }
  getAnswer1PercentChart() {
    return this.answer1PercentChart;
  }
  getAnswer2PercentChart() {
    return this.answer2PercentChart;
  }
  getAnswer1Percent() {
    return this.answer1Percent;
  }
  getAnswer2Percent() {
    return this.answer2Percent;
  }
  getAnswer1Vote() {
    //console.log('answer1Vote: ' + this.answer1Vote)
    return this.answer1Vote;
  }
  getAnswer2Vote() {
    //console.log('answer2Vote: ' + this.answer2Vote)
    return this.answer2Vote;
  }
  getTotalVote() {
    return this.totalVote;
  }
  getAnswer1() {
    return this.answer1;
  }
  getAnswer2() {
    return this.answer2;
  }
  getQuestion() {
    return this.question;
  }
  getMBTIType() {
    return this.MBTIType;
  }

  async openNativeBrowser(url: string) {
    await this.getData("color-theme").then(async (theme) => {
      let browserColor: string = "#000000";
      let textColor: string = "#0ec6fe";
      if (theme == "dark" || theme == null) {
        browserColor = "#000000";
        // textColor = '#0ec6fe';
      } else {
        browserColor = "#ffffff";
        // textColor = '#0ec6fe';
      }
      await this.safariViewController.isAvailable().then(async (available: boolean) => {
        if (available) {
          await this.safariViewController
            .show({
              url: url,
              hidden: false,
              animated: true,
              transition: "curl",
              enterReaderModeIfAvailable: true,
              tintColor: textColor,
              barColor: browserColor,
              controlTintColor: textColor,
            })
            .subscribe(
              (result: any) => {
                if (result.event === "opened") console.log("Opened");
                else if (result.event === "loaded") console.log("Loaded");
                else if (result.event === "closed") console.log("Closed");
              },
              (error: any) => console.error(error)
            );
        } else {
          await this.inAppBrowser.create(url);
          // use fallback browser, example InAppBrowser
        }
      });
    });
  }

  public initializeNetworkEvents() {
    if (this.status.getValue() === ConnectionStatus.Online) {
      // this.presentLoading('OFFINE', 100);
      this.setData("isOnline", false);
    } else if (this.status.getValue() === ConnectionStatus.Offline) {
      // this.presentLoading('ONLINE', 100);
      this.setData("isOnline", true);
    }

    this.network.onDisconnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.Online) {
        this.updateNetworkStatus(ConnectionStatus.Offline);
        // this.presentLoading('ONLINE', 100);
        this.setData("isOnline", true);
      }
    });

    this.network.onConnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.Offline) {
        this.updateNetworkStatus(ConnectionStatus.Online);
        // this.presentLoading('ONLINE', 100);
        this.setData("isOnline", true);
      }
    });
  }

  async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
    let connection = status == ConnectionStatus.Offline ? "Offline" : "Online";
    // this.setData('ONLINE', connection);
    // this.presentLoading(connection, 100);
    // this.presentLoading('ONLINE', 100);
    // this.setData('isOnline', true);
  }

  onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  async setDataNative(key: string, data: any): Promise<any> {
    try {
      const result = await this.nativeStorage.setItem(key, data);
      // alert("data: " + JSON.stringify(result))
      return true;
    } catch (reason) {
      console.log("reason: " + reason);
      return false;
    }
  }

  // to get a key/value pair
  async getDataNative(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }

  // set a key/value
  async setData(key: string, data: any): Promise<any> {
    try {
      const result = await this.storage.set(key, data);
      // alert("data: " + JSON.stringify(result))
      return true;
    } catch (reason) {
      console.log("reason: " + reason);
      return false;
    }
  }

  // to get a key/value pair
  async getData(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }
  publishEventData(data: any) {
    this.eventSubject.next(data);
  }
  getObservable(): Subject<any> {
    return this.eventSubject;
  }
  publishAuthenticatedData(data: any) {
    this.authenticatedSubject.next(data);
  }
  getAuthenticatedObservable(): Subject<any> {
    return this.authenticatedSubject;
  }

  getLoadingMessage() {
    let index = this.getRandomInt(loadingText.length);
    return loadingText[index];
  }

  getAppText(key: string) {
    return appText[key];
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  // remove a single key value:
  removeData(key: string) {
    this.storage.remove(key);
  }

  // delete all data from your application:
  clearData() {
    this.storage.clear();
  }

  // For OBJECT SPECIFIC

  // set a key/value object
  async setObject(key: string, object: Object) {
    try {
      const result = await this.storage.set(key, JSON.stringify(object));
      return true;
    } catch (reason) {
      console.log(reason);
      return false;
    }
  }

  // get a key/value object
  async getObject(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return JSON.parse(result);
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }

  async presentLoading(msg: string, duration: number, css?: string) {
    this.loadingProgress = await this.loadingCtrl.create({
      message: msg,
      duration: duration,
      cssClass: css,
      // spinner: null
    });
    await this.loadingProgress.present();
  }

  onScroll(event) {
    // used a couple of "guards" to prevent unnecessary assignments if scrolling in a direction and the var is set already:
    if (event.detail.deltaY > 0 && this.hideToolbars) return;
    if (event.detail.deltaY < 0 && this.hideToolbars) return;
    if (event.detail.deltaY > 0) {
      //console.log("scrolling down, hiding footer...");
      return (this.hideToolbars = true);
    } else {
      //console.log("scrolling up, revealing footer...");
      return (this.hideToolbars = false);
    }
  }

  hideLoading() {
    this.loadingProgress.dismiss();
  }
}
