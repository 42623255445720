import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { RedditService } from "../app//services/api/index";
import { CommonService } from "../app/services/common/index";
import { LanguageService } from "../app/services/language/language.service";
import { TranslateService } from "@ngx-translate/core";
import { APIService } from "./API.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { UserInfo, UserInfoList } from "./models/awsUserToken";
import { TimeService } from "./services/api/index";
// import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { JS } from "aws-amplify";
import { dailyPoll } from "./models";
// import { LaunchReview } from '@ionic-native/launch-review/ngx';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  todos: Array<any>;
  i: number = 0;
  private utcMonth: string;
  private utc: string;
  private chatroomData: any;
  private dailyPollQuestion: string;
  private targetDate: Date;
  private targetDateString: string;
  private appVersionString: string = "";

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public reddit: RedditService,
    public commonService: CommonService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private apiService: APIService,
    private router: Router,
    private appVersion: AppVersion,
    private timeService: TimeService,
    // private localNotifications: LocalNotifications
    // private launchReview: LaunchReview,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready().then(async () => {

      await this.commonService.initializeNetworkEvents();
      await this.timeService.getUTCTime();
      await this.fetchData();
      await this.translateService.setDefaultLang("en");
      await this.appVersion.getVersionNumber().then(async (appVersion) => {
        await this.commonService.getData("appVersion").then(async (appVersionLocal) => {
          // alert("appVersionLocal: " + appVersionLocal)
          this.appVersionString = appVersionLocal;
          if (appVersionLocal != appVersion) {
            await this.commonService.setData("appVersion", appVersion);
            this.appVersionString = appVersionLocal;
          }
        });
      });
      // this.commonService.appVersion = this.appVersion.getVersionNumber();
      await this.commonService.getData("color-theme").then((theme) => {
        if (theme == "dark") {
          document.body.setAttribute("color-theme", theme);
          this.statusBar.styleLightContent();
        } else if (theme == "light") {
          document.body.setAttribute("color-theme", theme);
          this.statusBar.styleDefault();
        } else {
          this.commonService.setData("color-theme", "dark");
          document.body.setAttribute("color-theme", "dark");
          this.statusBar.styleLightContent();
        }
      })
      await this.commonService
        .getData("hand-orientation")
        .then((orientation) => {
          if (orientation == undefined && orientation == null) {
            this.commonService.setData("hand-orientation", "right");
          }
        });
      // await this.localNotifications.on('click').subscribe((res) => {
      //   alert("local notification: " + JSON.stringify(res))
      //   this.router.navigate(["tabs/tab2"]);
      // });
      // await this.commonService.getData("MBTI_Type").then(async (data: string) => {
      //   if (data != null) {
      //     await this.commonService.getFuturePromptLocalNotification().then((dailyPollQuestion) => {
      //       this.dailyPollQuestion = dailyPollQuestion;
      //     });
      //     await this.commonService.scheduleLocalNotification(this.dailyPollQuestion);
      //   }
      // });
      await this.commonService.getData("firstTime").then(async (data) => {
        // alert('firstTime1: ' + data)
        await this.commonService.setData("firstTime", true);
        if (!data) {
          // alert('firstTime2: ' + data);
          // alert('desktop: ' + this.platform.is('desktop'))
          if (!this.platform.is('desktop')) {
            this.router.navigate(["tutorial"]);
            // await this.commonService.openNativeBrowser("https://www.boredyet.me/privacy");
          }
          // else{
          //   this.router.navigate(["tabs"]);
          // }
        } else {
          await this.commonService
            .getData("authenticated")
            .then(async (data) => {
              if (data == false && data == undefined && data == null) {
                // this.commonService.passwordFieldFlag = false;
                // this.commonService.authenticated = false;
                // alert('not authenticated')
                await this.commonService.publishAuthenticatedData({
                  authenticated: "false",
                });
                if (!this.platform.is('desktop')) {
                  this.router.navigate(["signup"]);
                }
              } else {
                // alert('authenticated')
                // this.commonService.passwordFieldFlag = true;
                // this.commonService.authenticated = true;
                await this.commonService.publishAuthenticatedData({
                  authenticated: "true",
                });
              }
            });
        }
      });
      await this.commonService
        .getData("awsUserInfo")
        .then(async (data: UserInfo) => {
          // console.log('awsUserInfo: ' + JSON.stringify(data))
          if (data != undefined && data != null && data.username != "") {
            // alert('app component data: ' + JSON.stringify(data.items[0].username));
            await this.updateUserAPILogin(data.id);
            if (
              data.mbti == undefined &&
              data.mbti == null &&
              data.mbti == ""
            ) {
              this.router.navigate(["tabs/tab2"]);
            }
          }
        });

      let windowPlugins: any = window["plugins"];
      //Remove this method to stop OneSignal Debugging 
      windowPlugins.OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });

      var notificationOpenedCallback = function (jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      };
      // Set your iOS Settings
      var iosSettings = {};
      iosSettings["kOSSettingsKeyAutoPrompt"] = false;
      iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;
      windowPlugins.OneSignal
        .startInit("9f9f6950-05b6-4003-93f2-a66732ffbd2b", "909986479882")
        .inFocusDisplaying(windowPlugins.OneSignal.OSInFocusDisplayOption.None)
        .handleNotificationOpened(notificationOpenedCallback)
        .iOSSettings(iosSettings)
        .endInit();
      await this.splashScreen.hide();
    });
  }

  async checkAppVersion() {
  }

  async updateUserAPILogin(id: string) {
    await this.commonService.getSingleAWSUserId(id).then(async (data: any) => {
      // console.log('updateUserAPILogin: ' + JSON.stringify(data))
      let userInfoVar: UserInfo;
      userInfoVar = data;
      // console.log('data: ' + JSON.stringify(data))
      userInfoVar.appVersion = this.appVersionString;
      userInfoVar.totalSessions++;
      // alert('MBTI from API: ' + userInfoVar.mbti)
      await this.apiService.UpdateUser({
        id: userInfoVar.id,
        appVersion: userInfoVar.appVersion,
        totalSessions: userInfoVar.totalSessions,
      });
      // need to update chatroom id if createdat is not current month
      await this.commonService.getData("utc").then(async (utc) => {
        this.utcMonth = utc.substr(0, 7);
        this.utc = utc;
      });

      if (data.chatroomId != "" && data.chatroomId != undefined &&
        data.askBootlegActivated != null && data.askBootlegActivated != undefined && data.askBootlegActivated != false) {
        // check if current room is current month
        // either joins a room or create a new room
        // current room is not current month
        if (!data.chatroomId.includes(this.utcMonth)) {
          await this.commonService.presentLoading(this.translateService.instant("APP_MESSAGES.JOIN_NEW_ROOM"), 2000)
          let ModelSortDirection: any = "DESC"
          await this.apiService.ListChatrooms(
            "active", { beginsWith: this.utcMonth }, undefined, undefined, undefined, ModelSortDirection
          ).then(async (currentMonthChatrooms) => {
            // no room, create one
            if (currentMonthChatrooms.items.length < 1) {
              await this.createChatroom(userInfoVar.id, data);
              // there's a room here
            } else {
              this.chatroomData = currentMonthChatrooms;
              let membersArray: string[] = JSON.parse(currentMonthChatrooms.items[0].members)
              if (!currentMonthChatrooms.items[0].members.includes(userInfoVar.id)) {
                membersArray.push(userInfoVar.id)
                currentMonthChatrooms.items[0].size += 1
              }
              await this.apiService.UpdateChatroom({
                flag: "active",
                createdAt: this.chatroomData.items[0].createdAt,
                size: currentMonthChatrooms.items[0].size,
                members: JSON.stringify(membersArray),
              });
              let windowPlugins: any = window["plugins"];
              windowPlugins.OneSignal.sendTag("chatroomId", this.chatroomData.items[0].createdAt);
            }
          })
        }
        await this.apiService.UpdateUser({
          id: userInfoVar.id,
          chatroomId: this.chatroomData.items[0].createdAt
        })
        userInfoVar.chatroomId = this.chatroomData.items[0].createdAt;
        // });
      }
      // alert("app componenet userInfoVar: " + JSON.stringify(userInfoVar.chatroomId))
      await this.commonService.setData("awsUserInfo", userInfoVar);
    });
  }

  async createChatroom(memberId: string, userInfoVar: UserInfo) {
    try {
      let memberArray: string[] = [];
      memberArray.push(memberId)
      await this.apiService.CreateChatroom({
        size: 1,
        flag: "active",
        members: JSON.stringify(memberArray)
      }).then((data) => {
        console.log("room created: " + JSON.stringify(data))
        userInfoVar.chatroomId = data.createdAt;
        let windowPlugins: any = window["plugins"];
        windowPlugins.OneSignal.sendTag("chatroomId", data.createdAt);
      });
      // await this.apiService.UpdateUser({
      //   id: memberId
      // })
      await this.commonService.setData("awsUserInfo", userInfoVar);
    } catch (error) {
      alert(error.errors[0].message);
    }
  }

  async fetchData() {
    let subreddit: string = "popular";
    await this.reddit.getInitSubReddit(subreddit);
    await this.reddit.getPreferenceInitSubReddit('mbti');
    await this.reddit.getPagingSubReddit(subreddit);
    await this.reddit.getPreferencePagingSubReddit('mbti');
  }
}