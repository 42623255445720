/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://op5nb577evcenicwmrpps7wple.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-akgksmzv4vdabhme6ddlpvkgau",
    "aws_cognito_identity_pool_id": "us-west-2:8e3c05eb-7383-437b-801c-f1323d07b784",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_xS6POsan3",
    "aws_user_pools_web_client_id": "4smtlo4b6f7nbin3sgkplnq0oq",
    "oauth": {}
};


export default awsmobile;
