/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateTodoInput = {
  id?: string | null;
  name: string;
  description?: string | null;
  forceUpdateUseless?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelTodoConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  forceUpdateUseless?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTodoConditionInput | null> | null;
  or?: Array<ModelTodoConditionInput | null> | null;
  not?: ModelTodoConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type UpdateTodoInput = {
  id: string;
  name?: string | null;
  description?: string | null;
  forceUpdateUseless?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteTodoInput = {
  id?: string | null;
};

export type CreateChatroomInput = {
  flag: string;
  size: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  members: string;
};

export type ModelChatroomConditionInput = {
  size?: ModelIntInput | null;
  updatedAt?: ModelStringInput | null;
  members?: ModelStringInput | null;
  and?: Array<ModelChatroomConditionInput | null> | null;
  or?: Array<ModelChatroomConditionInput | null> | null;
  not?: ModelChatroomConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateChatroomInput = {
  flag: string;
  size?: number | null;
  createdAt: string;
  updatedAt?: string | null;
  members?: string | null;
};

export type DeleteChatroomInput = {
  flag: string;
  createdAt: string;
};

export type CreateMessageInput = {
  id?: string | null;
  chatroomId: string;
  type: string;
  body: string;
  sender: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelMessageConditionInput = {
  type?: ModelStringInput | null;
  body?: ModelStringInput | null;
  sender?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMessageConditionInput | null> | null;
  or?: Array<ModelMessageConditionInput | null> | null;
  not?: ModelMessageConditionInput | null;
};

export type UpdateMessageInput = {
  id: string;
  chatroomId: string;
  type?: string | null;
  body?: string | null;
  sender?: string | null;
  createdAt: string;
  updatedAt?: string | null;
};

export type DeleteMessageInput = {
  chatroomId: string;
  createdAt: string;
};

export type CreateUserInput = {
  id?: string | null;
  username: string;
  email: string;
  askBootlegActivated: boolean;
  birthday: string;
  gender: string;
  totalSessions: number;
  disabled: boolean;
  redditPreference: string;
  mbti?: string | null;
  handOrientation?: string | null;
  appThemeMode?: string | null;
  appVersion?: string | null;
  ethnicity?: string | null;
  feedLikes?: string | null;
  location?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  relationship?: string | null;
  employment?: string | null;
  profession?: string | null;
  chatroomId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  pushNotification?: boolean | null;
};

export type ModelUserConditionInput = {
  username?: ModelStringInput | null;
  email?: ModelStringInput | null;
  askBootlegActivated?: ModelBooleanInput | null;
  birthday?: ModelStringInput | null;
  gender?: ModelStringInput | null;
  totalSessions?: ModelIntInput | null;
  disabled?: ModelBooleanInput | null;
  redditPreference?: ModelStringInput | null;
  mbti?: ModelStringInput | null;
  handOrientation?: ModelStringInput | null;
  appThemeMode?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  ethnicity?: ModelStringInput | null;
  feedLikes?: ModelStringInput | null;
  location?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  relationship?: ModelStringInput | null;
  employment?: ModelStringInput | null;
  profession?: ModelStringInput | null;
  chatroomId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  pushNotification?: ModelBooleanInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateUserInput = {
  id: string;
  username?: string | null;
  email?: string | null;
  askBootlegActivated?: boolean | null;
  birthday?: string | null;
  gender?: string | null;
  totalSessions?: number | null;
  disabled?: boolean | null;
  redditPreference?: string | null;
  mbti?: string | null;
  handOrientation?: string | null;
  appThemeMode?: string | null;
  appVersion?: string | null;
  ethnicity?: string | null;
  feedLikes?: string | null;
  location?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  relationship?: string | null;
  employment?: string | null;
  profession?: string | null;
  chatroomId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  pushNotification?: boolean | null;
};

export type DeleteUserInput = {
  id?: string | null;
};

export type CreateDailypollInput = {
  id: string;
  question: string;
  answer1: string;
  answer2: string;
  happyVote?: number | null;
  frustratedVote?: number | null;
  totalVote: number;
  answer1Vote: number;
  answer1Percent: number;
  answer1PercentChart: number;
  answer2Vote: number;
  answer2Percent: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  ENTPAnswer1Vote: number;
  ENTPAnswer2Vote: number;
  ENTJAnswer1Vote: number;
  ENTJAnswer2Vote: number;
  INTPAnswer1Vote: number;
  INTPAnswer2Vote: number;
  INTJAnswer1Vote: number;
  INTJAnswer2Vote: number;
  ESTPAnswer1Vote: number;
  ESTPAnswer2Vote: number;
  ESTJAnswer1Vote: number;
  ESTJAnswer2Vote: number;
  ISTPAnswer1Vote: number;
  ISTPAnswer2Vote: number;
  ISTJAnswer1Vote: number;
  ISTJAnswer2Vote: number;
  ENFPAnswer1Vote: number;
  ENFPAnswer2Vote: number;
  ENFJAnswer1Vote: number;
  ENFJAnswer2Vote: number;
  INFPAnswer1Vote: number;
  INFPAnswer2Vote: number;
  INFJAnswer1Vote: number;
  INFJAnswer2Vote: number;
  ESFPAnswer1Vote: number;
  ESFPAnswer2Vote: number;
  ESFJAnswer1Vote: number;
  ESFJAnswer2Vote: number;
  ISFPAnswer1Vote: number;
  ISFPAnswer2Vote: number;
  ISFJAnswer1Vote: number;
  ISFJAnswer2Vote: number;
};

export type ModelDailypollConditionInput = {
  question?: ModelStringInput | null;
  answer1?: ModelStringInput | null;
  answer2?: ModelStringInput | null;
  happyVote?: ModelIntInput | null;
  frustratedVote?: ModelIntInput | null;
  totalVote?: ModelIntInput | null;
  answer1Vote?: ModelIntInput | null;
  answer1Percent?: ModelFloatInput | null;
  answer1PercentChart?: ModelFloatInput | null;
  answer2Vote?: ModelIntInput | null;
  answer2Percent?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  ENTPAnswer1Vote?: ModelIntInput | null;
  ENTPAnswer2Vote?: ModelIntInput | null;
  ENTJAnswer1Vote?: ModelIntInput | null;
  ENTJAnswer2Vote?: ModelIntInput | null;
  INTPAnswer1Vote?: ModelIntInput | null;
  INTPAnswer2Vote?: ModelIntInput | null;
  INTJAnswer1Vote?: ModelIntInput | null;
  INTJAnswer2Vote?: ModelIntInput | null;
  ESTPAnswer1Vote?: ModelIntInput | null;
  ESTPAnswer2Vote?: ModelIntInput | null;
  ESTJAnswer1Vote?: ModelIntInput | null;
  ESTJAnswer2Vote?: ModelIntInput | null;
  ISTPAnswer1Vote?: ModelIntInput | null;
  ISTPAnswer2Vote?: ModelIntInput | null;
  ISTJAnswer1Vote?: ModelIntInput | null;
  ISTJAnswer2Vote?: ModelIntInput | null;
  ENFPAnswer1Vote?: ModelIntInput | null;
  ENFPAnswer2Vote?: ModelIntInput | null;
  ENFJAnswer1Vote?: ModelIntInput | null;
  ENFJAnswer2Vote?: ModelIntInput | null;
  INFPAnswer1Vote?: ModelIntInput | null;
  INFPAnswer2Vote?: ModelIntInput | null;
  INFJAnswer1Vote?: ModelIntInput | null;
  INFJAnswer2Vote?: ModelIntInput | null;
  ESFPAnswer1Vote?: ModelIntInput | null;
  ESFPAnswer2Vote?: ModelIntInput | null;
  ESFJAnswer1Vote?: ModelIntInput | null;
  ESFJAnswer2Vote?: ModelIntInput | null;
  ISFPAnswer1Vote?: ModelIntInput | null;
  ISFPAnswer2Vote?: ModelIntInput | null;
  ISFJAnswer1Vote?: ModelIntInput | null;
  ISFJAnswer2Vote?: ModelIntInput | null;
  and?: Array<ModelDailypollConditionInput | null> | null;
  or?: Array<ModelDailypollConditionInput | null> | null;
  not?: ModelDailypollConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateDailypollInput = {
  id: string;
  question?: string | null;
  answer1?: string | null;
  answer2?: string | null;
  happyVote?: number | null;
  frustratedVote?: number | null;
  totalVote?: number | null;
  answer1Vote?: number | null;
  answer1Percent?: number | null;
  answer1PercentChart?: number | null;
  answer2Vote?: number | null;
  answer2Percent?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  ENTPAnswer1Vote?: number | null;
  ENTPAnswer2Vote?: number | null;
  ENTJAnswer1Vote?: number | null;
  ENTJAnswer2Vote?: number | null;
  INTPAnswer1Vote?: number | null;
  INTPAnswer2Vote?: number | null;
  INTJAnswer1Vote?: number | null;
  INTJAnswer2Vote?: number | null;
  ESTPAnswer1Vote?: number | null;
  ESTPAnswer2Vote?: number | null;
  ESTJAnswer1Vote?: number | null;
  ESTJAnswer2Vote?: number | null;
  ISTPAnswer1Vote?: number | null;
  ISTPAnswer2Vote?: number | null;
  ISTJAnswer1Vote?: number | null;
  ISTJAnswer2Vote?: number | null;
  ENFPAnswer1Vote?: number | null;
  ENFPAnswer2Vote?: number | null;
  ENFJAnswer1Vote?: number | null;
  ENFJAnswer2Vote?: number | null;
  INFPAnswer1Vote?: number | null;
  INFPAnswer2Vote?: number | null;
  INFJAnswer1Vote?: number | null;
  INFJAnswer2Vote?: number | null;
  ESFPAnswer1Vote?: number | null;
  ESFPAnswer2Vote?: number | null;
  ESFJAnswer1Vote?: number | null;
  ESFJAnswer2Vote?: number | null;
  ISFPAnswer1Vote?: number | null;
  ISFPAnswer2Vote?: number | null;
  ISFJAnswer1Vote?: number | null;
  ISFJAnswer2Vote?: number | null;
};

export type DeleteDailypollInput = {
  id?: string | null;
};

export type ModelTodoFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  forceUpdateUseless?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelTodoFilterInput | null> | null;
  or?: Array<ModelTodoFilterInput | null> | null;
  not?: ModelTodoFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelChatroomFilterInput = {
  flag?: ModelStringInput | null;
  size?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  members?: ModelStringInput | null;
  and?: Array<ModelChatroomFilterInput | null> | null;
  or?: Array<ModelChatroomFilterInput | null> | null;
  not?: ModelChatroomFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null;
  chatroomId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  body?: ModelStringInput | null;
  sender?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMessageFilterInput | null> | null;
  or?: Array<ModelMessageFilterInput | null> | null;
  not?: ModelMessageFilterInput | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  username?: ModelStringInput | null;
  email?: ModelStringInput | null;
  askBootlegActivated?: ModelBooleanInput | null;
  birthday?: ModelStringInput | null;
  gender?: ModelStringInput | null;
  totalSessions?: ModelIntInput | null;
  disabled?: ModelBooleanInput | null;
  redditPreference?: ModelStringInput | null;
  mbti?: ModelStringInput | null;
  handOrientation?: ModelStringInput | null;
  appThemeMode?: ModelStringInput | null;
  appVersion?: ModelStringInput | null;
  ethnicity?: ModelStringInput | null;
  feedLikes?: ModelStringInput | null;
  location?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  relationship?: ModelStringInput | null;
  employment?: ModelStringInput | null;
  profession?: ModelStringInput | null;
  chatroomId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  pushNotification?: ModelBooleanInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelDailypollFilterInput = {
  id?: ModelStringInput | null;
  question?: ModelStringInput | null;
  answer1?: ModelStringInput | null;
  answer2?: ModelStringInput | null;
  happyVote?: ModelIntInput | null;
  frustratedVote?: ModelIntInput | null;
  totalVote?: ModelIntInput | null;
  answer1Vote?: ModelIntInput | null;
  answer1Percent?: ModelFloatInput | null;
  answer1PercentChart?: ModelFloatInput | null;
  answer2Vote?: ModelIntInput | null;
  answer2Percent?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  ENTPAnswer1Vote?: ModelIntInput | null;
  ENTPAnswer2Vote?: ModelIntInput | null;
  ENTJAnswer1Vote?: ModelIntInput | null;
  ENTJAnswer2Vote?: ModelIntInput | null;
  INTPAnswer1Vote?: ModelIntInput | null;
  INTPAnswer2Vote?: ModelIntInput | null;
  INTJAnswer1Vote?: ModelIntInput | null;
  INTJAnswer2Vote?: ModelIntInput | null;
  ESTPAnswer1Vote?: ModelIntInput | null;
  ESTPAnswer2Vote?: ModelIntInput | null;
  ESTJAnswer1Vote?: ModelIntInput | null;
  ESTJAnswer2Vote?: ModelIntInput | null;
  ISTPAnswer1Vote?: ModelIntInput | null;
  ISTPAnswer2Vote?: ModelIntInput | null;
  ISTJAnswer1Vote?: ModelIntInput | null;
  ISTJAnswer2Vote?: ModelIntInput | null;
  ENFPAnswer1Vote?: ModelIntInput | null;
  ENFPAnswer2Vote?: ModelIntInput | null;
  ENFJAnswer1Vote?: ModelIntInput | null;
  ENFJAnswer2Vote?: ModelIntInput | null;
  INFPAnswer1Vote?: ModelIntInput | null;
  INFPAnswer2Vote?: ModelIntInput | null;
  INFJAnswer1Vote?: ModelIntInput | null;
  INFJAnswer2Vote?: ModelIntInput | null;
  ESFPAnswer1Vote?: ModelIntInput | null;
  ESFPAnswer2Vote?: ModelIntInput | null;
  ESFJAnswer1Vote?: ModelIntInput | null;
  ESFJAnswer2Vote?: ModelIntInput | null;
  ISFPAnswer1Vote?: ModelIntInput | null;
  ISFPAnswer2Vote?: ModelIntInput | null;
  ISFJAnswer1Vote?: ModelIntInput | null;
  ISFJAnswer2Vote?: ModelIntInput | null;
  and?: Array<ModelDailypollFilterInput | null> | null;
  or?: Array<ModelDailypollFilterInput | null> | null;
  not?: ModelDailypollFilterInput | null;
};

export type CreateTodoMutation = {
  __typename: "Todo";
  id: string;
  name: string;
  description: string | null;
  forceUpdateUseless: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type UpdateTodoMutation = {
  __typename: "Todo";
  id: string;
  name: string;
  description: string | null;
  forceUpdateUseless: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type DeleteTodoMutation = {
  __typename: "Todo";
  id: string;
  name: string;
  description: string | null;
  forceUpdateUseless: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type CreateChatroomMutation = {
  __typename: "Chatroom";
  flag: string;
  size: number;
  createdAt: string;
  updatedAt: string | null;
  members: string;
};

export type UpdateChatroomMutation = {
  __typename: "Chatroom";
  flag: string;
  size: number;
  createdAt: string;
  updatedAt: string | null;
  members: string;
};

export type DeleteChatroomMutation = {
  __typename: "Chatroom";
  flag: string;
  size: number;
  createdAt: string;
  updatedAt: string | null;
  members: string;
};

export type CreateMessageMutation = {
  __typename: "Message";
  id: string;
  chatroomId: string;
  type: string;
  body: string;
  sender: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMessageMutation = {
  __typename: "Message";
  id: string;
  chatroomId: string;
  type: string;
  body: string;
  sender: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMessageMutation = {
  __typename: "Message";
  id: string;
  chatroomId: string;
  type: string;
  body: string;
  sender: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  username: string;
  email: string;
  askBootlegActivated: boolean;
  birthday: string;
  gender: string;
  totalSessions: number;
  disabled: boolean;
  redditPreference: string;
  mbti: string | null;
  handOrientation: string | null;
  appThemeMode: string | null;
  appVersion: string | null;
  ethnicity: string | null;
  feedLikes: string | null;
  location: string | null;
  firstName: string | null;
  lastName: string | null;
  relationship: string | null;
  employment: string | null;
  profession: string | null;
  chatroomId: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  pushNotification: boolean | null;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  username: string;
  email: string;
  askBootlegActivated: boolean;
  birthday: string;
  gender: string;
  totalSessions: number;
  disabled: boolean;
  redditPreference: string;
  mbti: string | null;
  handOrientation: string | null;
  appThemeMode: string | null;
  appVersion: string | null;
  ethnicity: string | null;
  feedLikes: string | null;
  location: string | null;
  firstName: string | null;
  lastName: string | null;
  relationship: string | null;
  employment: string | null;
  profession: string | null;
  chatroomId: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  pushNotification: boolean | null;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  username: string;
  email: string;
  askBootlegActivated: boolean;
  birthday: string;
  gender: string;
  totalSessions: number;
  disabled: boolean;
  redditPreference: string;
  mbti: string | null;
  handOrientation: string | null;
  appThemeMode: string | null;
  appVersion: string | null;
  ethnicity: string | null;
  feedLikes: string | null;
  location: string | null;
  firstName: string | null;
  lastName: string | null;
  relationship: string | null;
  employment: string | null;
  profession: string | null;
  chatroomId: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  pushNotification: boolean | null;
};

export type CreateDailypollMutation = {
  __typename: "Dailypoll";
  id: string;
  question: string;
  answer1: string;
  answer2: string;
  happyVote: number | null;
  frustratedVote: number | null;
  totalVote: number;
  answer1Vote: number;
  answer1Percent: number;
  answer1PercentChart: number;
  answer2Vote: number;
  answer2Percent: number;
  createdAt: string | null;
  updatedAt: string | null;
  ENTPAnswer1Vote: number;
  ENTPAnswer2Vote: number;
  ENTJAnswer1Vote: number;
  ENTJAnswer2Vote: number;
  INTPAnswer1Vote: number;
  INTPAnswer2Vote: number;
  INTJAnswer1Vote: number;
  INTJAnswer2Vote: number;
  ESTPAnswer1Vote: number;
  ESTPAnswer2Vote: number;
  ESTJAnswer1Vote: number;
  ESTJAnswer2Vote: number;
  ISTPAnswer1Vote: number;
  ISTPAnswer2Vote: number;
  ISTJAnswer1Vote: number;
  ISTJAnswer2Vote: number;
  ENFPAnswer1Vote: number;
  ENFPAnswer2Vote: number;
  ENFJAnswer1Vote: number;
  ENFJAnswer2Vote: number;
  INFPAnswer1Vote: number;
  INFPAnswer2Vote: number;
  INFJAnswer1Vote: number;
  INFJAnswer2Vote: number;
  ESFPAnswer1Vote: number;
  ESFPAnswer2Vote: number;
  ESFJAnswer1Vote: number;
  ESFJAnswer2Vote: number;
  ISFPAnswer1Vote: number;
  ISFPAnswer2Vote: number;
  ISFJAnswer1Vote: number;
  ISFJAnswer2Vote: number;
};

export type UpdateDailypollMutation = {
  __typename: "Dailypoll";
  id: string;
  question: string;
  answer1: string;
  answer2: string;
  happyVote: number | null;
  frustratedVote: number | null;
  totalVote: number;
  answer1Vote: number;
  answer1Percent: number;
  answer1PercentChart: number;
  answer2Vote: number;
  answer2Percent: number;
  createdAt: string | null;
  updatedAt: string | null;
  ENTPAnswer1Vote: number;
  ENTPAnswer2Vote: number;
  ENTJAnswer1Vote: number;
  ENTJAnswer2Vote: number;
  INTPAnswer1Vote: number;
  INTPAnswer2Vote: number;
  INTJAnswer1Vote: number;
  INTJAnswer2Vote: number;
  ESTPAnswer1Vote: number;
  ESTPAnswer2Vote: number;
  ESTJAnswer1Vote: number;
  ESTJAnswer2Vote: number;
  ISTPAnswer1Vote: number;
  ISTPAnswer2Vote: number;
  ISTJAnswer1Vote: number;
  ISTJAnswer2Vote: number;
  ENFPAnswer1Vote: number;
  ENFPAnswer2Vote: number;
  ENFJAnswer1Vote: number;
  ENFJAnswer2Vote: number;
  INFPAnswer1Vote: number;
  INFPAnswer2Vote: number;
  INFJAnswer1Vote: number;
  INFJAnswer2Vote: number;
  ESFPAnswer1Vote: number;
  ESFPAnswer2Vote: number;
  ESFJAnswer1Vote: number;
  ESFJAnswer2Vote: number;
  ISFPAnswer1Vote: number;
  ISFPAnswer2Vote: number;
  ISFJAnswer1Vote: number;
  ISFJAnswer2Vote: number;
};

export type DeleteDailypollMutation = {
  __typename: "Dailypoll";
  id: string;
  question: string;
  answer1: string;
  answer2: string;
  happyVote: number | null;
  frustratedVote: number | null;
  totalVote: number;
  answer1Vote: number;
  answer1Percent: number;
  answer1PercentChart: number;
  answer2Vote: number;
  answer2Percent: number;
  createdAt: string | null;
  updatedAt: string | null;
  ENTPAnswer1Vote: number;
  ENTPAnswer2Vote: number;
  ENTJAnswer1Vote: number;
  ENTJAnswer2Vote: number;
  INTPAnswer1Vote: number;
  INTPAnswer2Vote: number;
  INTJAnswer1Vote: number;
  INTJAnswer2Vote: number;
  ESTPAnswer1Vote: number;
  ESTPAnswer2Vote: number;
  ESTJAnswer1Vote: number;
  ESTJAnswer2Vote: number;
  ISTPAnswer1Vote: number;
  ISTPAnswer2Vote: number;
  ISTJAnswer1Vote: number;
  ISTJAnswer2Vote: number;
  ENFPAnswer1Vote: number;
  ENFPAnswer2Vote: number;
  ENFJAnswer1Vote: number;
  ENFJAnswer2Vote: number;
  INFPAnswer1Vote: number;
  INFPAnswer2Vote: number;
  INFJAnswer1Vote: number;
  INFJAnswer2Vote: number;
  ESFPAnswer1Vote: number;
  ESFPAnswer2Vote: number;
  ESFJAnswer1Vote: number;
  ESFJAnswer2Vote: number;
  ISFPAnswer1Vote: number;
  ISFPAnswer2Vote: number;
  ISFJAnswer1Vote: number;
  ISFJAnswer2Vote: number;
};

export type GetTodoQuery = {
  __typename: "Todo";
  id: string;
  name: string;
  description: string | null;
  forceUpdateUseless: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type ListTodosQuery = {
  __typename: "ModelTodoConnection";
  items: Array<{
    __typename: "Todo";
    id: string;
    name: string;
    description: string | null;
    forceUpdateUseless: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetChatroomQuery = {
  __typename: "Chatroom";
  flag: string;
  size: number;
  createdAt: string;
  updatedAt: string | null;
  members: string;
};

export type ListChatroomsQuery = {
  __typename: "ModelChatroomConnection";
  items: Array<{
    __typename: "Chatroom";
    flag: string;
    size: number;
    createdAt: string;
    updatedAt: string | null;
    members: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetMessageQuery = {
  __typename: "Message";
  id: string;
  chatroomId: string;
  type: string;
  body: string;
  sender: string;
  createdAt: string;
  updatedAt: string;
};

export type ListMessagesQuery = {
  __typename: "ModelMessageConnection";
  items: Array<{
    __typename: "Message";
    id: string;
    chatroomId: string;
    type: string;
    body: string;
    sender: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  username: string;
  email: string;
  askBootlegActivated: boolean;
  birthday: string;
  gender: string;
  totalSessions: number;
  disabled: boolean;
  redditPreference: string;
  mbti: string | null;
  handOrientation: string | null;
  appThemeMode: string | null;
  appVersion: string | null;
  ethnicity: string | null;
  feedLikes: string | null;
  location: string | null;
  firstName: string | null;
  lastName: string | null;
  relationship: string | null;
  employment: string | null;
  profession: string | null;
  chatroomId: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  pushNotification: boolean | null;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    username: string;
    email: string;
    askBootlegActivated: boolean;
    birthday: string;
    gender: string;
    totalSessions: number;
    disabled: boolean;
    redditPreference: string;
    mbti: string | null;
    handOrientation: string | null;
    appThemeMode: string | null;
    appVersion: string | null;
    ethnicity: string | null;
    feedLikes: string | null;
    location: string | null;
    firstName: string | null;
    lastName: string | null;
    relationship: string | null;
    employment: string | null;
    profession: string | null;
    chatroomId: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    pushNotification: boolean | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetDailypollQuery = {
  __typename: "Dailypoll";
  id: string;
  question: string;
  answer1: string;
  answer2: string;
  happyVote: number | null;
  frustratedVote: number | null;
  totalVote: number;
  answer1Vote: number;
  answer1Percent: number;
  answer1PercentChart: number;
  answer2Vote: number;
  answer2Percent: number;
  createdAt: string | null;
  updatedAt: string | null;
  ENTPAnswer1Vote: number;
  ENTPAnswer2Vote: number;
  ENTJAnswer1Vote: number;
  ENTJAnswer2Vote: number;
  INTPAnswer1Vote: number;
  INTPAnswer2Vote: number;
  INTJAnswer1Vote: number;
  INTJAnswer2Vote: number;
  ESTPAnswer1Vote: number;
  ESTPAnswer2Vote: number;
  ESTJAnswer1Vote: number;
  ESTJAnswer2Vote: number;
  ISTPAnswer1Vote: number;
  ISTPAnswer2Vote: number;
  ISTJAnswer1Vote: number;
  ISTJAnswer2Vote: number;
  ENFPAnswer1Vote: number;
  ENFPAnswer2Vote: number;
  ENFJAnswer1Vote: number;
  ENFJAnswer2Vote: number;
  INFPAnswer1Vote: number;
  INFPAnswer2Vote: number;
  INFJAnswer1Vote: number;
  INFJAnswer2Vote: number;
  ESFPAnswer1Vote: number;
  ESFPAnswer2Vote: number;
  ESFJAnswer1Vote: number;
  ESFJAnswer2Vote: number;
  ISFPAnswer1Vote: number;
  ISFPAnswer2Vote: number;
  ISFJAnswer1Vote: number;
  ISFJAnswer2Vote: number;
};

export type ListDailypollsQuery = {
  __typename: "ModelDailypollConnection";
  items: Array<{
    __typename: "Dailypoll";
    id: string;
    question: string;
    answer1: string;
    answer2: string;
    happyVote: number | null;
    frustratedVote: number | null;
    totalVote: number;
    answer1Vote: number;
    answer1Percent: number;
    answer1PercentChart: number;
    answer2Vote: number;
    answer2Percent: number;
    createdAt: string | null;
    updatedAt: string | null;
    ENTPAnswer1Vote: number;
    ENTPAnswer2Vote: number;
    ENTJAnswer1Vote: number;
    ENTJAnswer2Vote: number;
    INTPAnswer1Vote: number;
    INTPAnswer2Vote: number;
    INTJAnswer1Vote: number;
    INTJAnswer2Vote: number;
    ESTPAnswer1Vote: number;
    ESTPAnswer2Vote: number;
    ESTJAnswer1Vote: number;
    ESTJAnswer2Vote: number;
    ISTPAnswer1Vote: number;
    ISTPAnswer2Vote: number;
    ISTJAnswer1Vote: number;
    ISTJAnswer2Vote: number;
    ENFPAnswer1Vote: number;
    ENFPAnswer2Vote: number;
    ENFJAnswer1Vote: number;
    ENFJAnswer2Vote: number;
    INFPAnswer1Vote: number;
    INFPAnswer2Vote: number;
    INFJAnswer1Vote: number;
    INFJAnswer2Vote: number;
    ESFPAnswer1Vote: number;
    ESFPAnswer2Vote: number;
    ESFJAnswer1Vote: number;
    ESFJAnswer2Vote: number;
    ISFPAnswer1Vote: number;
    ISFPAnswer2Vote: number;
    ISFJAnswer1Vote: number;
    ISFJAnswer2Vote: number;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateTodoSubscription = {
  __typename: "Todo";
  id: string;
  name: string;
  description: string | null;
  forceUpdateUseless: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnUpdateTodoSubscription = {
  __typename: "Todo";
  id: string;
  name: string;
  description: string | null;
  forceUpdateUseless: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnDeleteTodoSubscription = {
  __typename: "Todo";
  id: string;
  name: string;
  description: string | null;
  forceUpdateUseless: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnCreateChatroomSubscription = {
  __typename: "Chatroom";
  flag: string;
  size: number;
  createdAt: string;
  updatedAt: string | null;
  members: string;
};

export type OnUpdateChatroomSubscription = {
  __typename: "Chatroom";
  flag: string;
  size: number;
  createdAt: string;
  updatedAt: string | null;
  members: string;
};

export type OnDeleteChatroomSubscription = {
  __typename: "Chatroom";
  flag: string;
  size: number;
  createdAt: string;
  updatedAt: string | null;
  members: string;
};

export type OnCreateMessageSubscription = {
  __typename: "Message";
  id: string;
  chatroomId: string;
  type: string;
  body: string;
  sender: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMessageSubscription = {
  __typename: "Message";
  id: string;
  chatroomId: string;
  type: string;
  body: string;
  sender: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMessageSubscription = {
  __typename: "Message";
  id: string;
  chatroomId: string;
  type: string;
  body: string;
  sender: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  username: string;
  email: string;
  askBootlegActivated: boolean;
  birthday: string;
  gender: string;
  totalSessions: number;
  disabled: boolean;
  redditPreference: string;
  mbti: string | null;
  handOrientation: string | null;
  appThemeMode: string | null;
  appVersion: string | null;
  ethnicity: string | null;
  feedLikes: string | null;
  location: string | null;
  firstName: string | null;
  lastName: string | null;
  relationship: string | null;
  employment: string | null;
  profession: string | null;
  chatroomId: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  pushNotification: boolean | null;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  username: string;
  email: string;
  askBootlegActivated: boolean;
  birthday: string;
  gender: string;
  totalSessions: number;
  disabled: boolean;
  redditPreference: string;
  mbti: string | null;
  handOrientation: string | null;
  appThemeMode: string | null;
  appVersion: string | null;
  ethnicity: string | null;
  feedLikes: string | null;
  location: string | null;
  firstName: string | null;
  lastName: string | null;
  relationship: string | null;
  employment: string | null;
  profession: string | null;
  chatroomId: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  pushNotification: boolean | null;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  username: string;
  email: string;
  askBootlegActivated: boolean;
  birthday: string;
  gender: string;
  totalSessions: number;
  disabled: boolean;
  redditPreference: string;
  mbti: string | null;
  handOrientation: string | null;
  appThemeMode: string | null;
  appVersion: string | null;
  ethnicity: string | null;
  feedLikes: string | null;
  location: string | null;
  firstName: string | null;
  lastName: string | null;
  relationship: string | null;
  employment: string | null;
  profession: string | null;
  chatroomId: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  pushNotification: boolean | null;
};

export type OnCreateDailypollSubscription = {
  __typename: "Dailypoll";
  id: string;
  question: string;
  answer1: string;
  answer2: string;
  happyVote: number | null;
  frustratedVote: number | null;
  totalVote: number;
  answer1Vote: number;
  answer1Percent: number;
  answer1PercentChart: number;
  answer2Vote: number;
  answer2Percent: number;
  createdAt: string | null;
  updatedAt: string | null;
  ENTPAnswer1Vote: number;
  ENTPAnswer2Vote: number;
  ENTJAnswer1Vote: number;
  ENTJAnswer2Vote: number;
  INTPAnswer1Vote: number;
  INTPAnswer2Vote: number;
  INTJAnswer1Vote: number;
  INTJAnswer2Vote: number;
  ESTPAnswer1Vote: number;
  ESTPAnswer2Vote: number;
  ESTJAnswer1Vote: number;
  ESTJAnswer2Vote: number;
  ISTPAnswer1Vote: number;
  ISTPAnswer2Vote: number;
  ISTJAnswer1Vote: number;
  ISTJAnswer2Vote: number;
  ENFPAnswer1Vote: number;
  ENFPAnswer2Vote: number;
  ENFJAnswer1Vote: number;
  ENFJAnswer2Vote: number;
  INFPAnswer1Vote: number;
  INFPAnswer2Vote: number;
  INFJAnswer1Vote: number;
  INFJAnswer2Vote: number;
  ESFPAnswer1Vote: number;
  ESFPAnswer2Vote: number;
  ESFJAnswer1Vote: number;
  ESFJAnswer2Vote: number;
  ISFPAnswer1Vote: number;
  ISFPAnswer2Vote: number;
  ISFJAnswer1Vote: number;
  ISFJAnswer2Vote: number;
};

export type OnUpdateDailypollSubscription = {
  __typename: "Dailypoll";
  id: string;
  question: string;
  answer1: string;
  answer2: string;
  happyVote: number | null;
  frustratedVote: number | null;
  totalVote: number;
  answer1Vote: number;
  answer1Percent: number;
  answer1PercentChart: number;
  answer2Vote: number;
  answer2Percent: number;
  createdAt: string | null;
  updatedAt: string | null;
  ENTPAnswer1Vote: number;
  ENTPAnswer2Vote: number;
  ENTJAnswer1Vote: number;
  ENTJAnswer2Vote: number;
  INTPAnswer1Vote: number;
  INTPAnswer2Vote: number;
  INTJAnswer1Vote: number;
  INTJAnswer2Vote: number;
  ESTPAnswer1Vote: number;
  ESTPAnswer2Vote: number;
  ESTJAnswer1Vote: number;
  ESTJAnswer2Vote: number;
  ISTPAnswer1Vote: number;
  ISTPAnswer2Vote: number;
  ISTJAnswer1Vote: number;
  ISTJAnswer2Vote: number;
  ENFPAnswer1Vote: number;
  ENFPAnswer2Vote: number;
  ENFJAnswer1Vote: number;
  ENFJAnswer2Vote: number;
  INFPAnswer1Vote: number;
  INFPAnswer2Vote: number;
  INFJAnswer1Vote: number;
  INFJAnswer2Vote: number;
  ESFPAnswer1Vote: number;
  ESFPAnswer2Vote: number;
  ESFJAnswer1Vote: number;
  ESFJAnswer2Vote: number;
  ISFPAnswer1Vote: number;
  ISFPAnswer2Vote: number;
  ISFJAnswer1Vote: number;
  ISFJAnswer2Vote: number;
};

export type OnDeleteDailypollSubscription = {
  __typename: "Dailypoll";
  id: string;
  question: string;
  answer1: string;
  answer2: string;
  happyVote: number | null;
  frustratedVote: number | null;
  totalVote: number;
  answer1Vote: number;
  answer1Percent: number;
  answer1PercentChart: number;
  answer2Vote: number;
  answer2Percent: number;
  createdAt: string | null;
  updatedAt: string | null;
  ENTPAnswer1Vote: number;
  ENTPAnswer2Vote: number;
  ENTJAnswer1Vote: number;
  ENTJAnswer2Vote: number;
  INTPAnswer1Vote: number;
  INTPAnswer2Vote: number;
  INTJAnswer1Vote: number;
  INTJAnswer2Vote: number;
  ESTPAnswer1Vote: number;
  ESTPAnswer2Vote: number;
  ESTJAnswer1Vote: number;
  ESTJAnswer2Vote: number;
  ISTPAnswer1Vote: number;
  ISTPAnswer2Vote: number;
  ISTJAnswer1Vote: number;
  ISTJAnswer2Vote: number;
  ENFPAnswer1Vote: number;
  ENFPAnswer2Vote: number;
  ENFJAnswer1Vote: number;
  ENFJAnswer2Vote: number;
  INFPAnswer1Vote: number;
  INFPAnswer2Vote: number;
  INFJAnswer1Vote: number;
  INFJAnswer2Vote: number;
  ESFPAnswer1Vote: number;
  ESFPAnswer2Vote: number;
  ESFJAnswer1Vote: number;
  ESFJAnswer2Vote: number;
  ISFPAnswer1Vote: number;
  ISFPAnswer2Vote: number;
  ISFJAnswer1Vote: number;
  ISFJAnswer2Vote: number;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateTodo(
    input: CreateTodoInput,
    condition?: ModelTodoConditionInput
  ): Promise<CreateTodoMutation> {
    const statement = `mutation CreateTodo($input: CreateTodoInput!, $condition: ModelTodoConditionInput) {
        createTodo(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          forceUpdateUseless
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTodoMutation>response.data.createTodo;
  }
  async UpdateTodo(
    input: UpdateTodoInput,
    condition?: ModelTodoConditionInput
  ): Promise<UpdateTodoMutation> {
    const statement = `mutation UpdateTodo($input: UpdateTodoInput!, $condition: ModelTodoConditionInput) {
        updateTodo(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          forceUpdateUseless
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTodoMutation>response.data.updateTodo;
  }
  async DeleteTodo(
    input: DeleteTodoInput,
    condition?: ModelTodoConditionInput
  ): Promise<DeleteTodoMutation> {
    const statement = `mutation DeleteTodo($input: DeleteTodoInput!, $condition: ModelTodoConditionInput) {
        deleteTodo(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          forceUpdateUseless
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTodoMutation>response.data.deleteTodo;
  }
  async CreateChatroom(
    input: CreateChatroomInput,
    condition?: ModelChatroomConditionInput
  ): Promise<CreateChatroomMutation> {
    const statement = `mutation CreateChatroom($input: CreateChatroomInput!, $condition: ModelChatroomConditionInput) {
        createChatroom(input: $input, condition: $condition) {
          __typename
          flag
          size
          createdAt
          updatedAt
          members
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateChatroomMutation>response.data.createChatroom;
  }
  async UpdateChatroom(
    input: UpdateChatroomInput,
    condition?: ModelChatroomConditionInput
  ): Promise<UpdateChatroomMutation> {
    const statement = `mutation UpdateChatroom($input: UpdateChatroomInput!, $condition: ModelChatroomConditionInput) {
        updateChatroom(input: $input, condition: $condition) {
          __typename
          flag
          size
          createdAt
          updatedAt
          members
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateChatroomMutation>response.data.updateChatroom;
  }
  async DeleteChatroom(
    input: DeleteChatroomInput,
    condition?: ModelChatroomConditionInput
  ): Promise<DeleteChatroomMutation> {
    const statement = `mutation DeleteChatroom($input: DeleteChatroomInput!, $condition: ModelChatroomConditionInput) {
        deleteChatroom(input: $input, condition: $condition) {
          __typename
          flag
          size
          createdAt
          updatedAt
          members
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteChatroomMutation>response.data.deleteChatroom;
  }
  async CreateMessage(
    input: CreateMessageInput,
    condition?: ModelMessageConditionInput
  ): Promise<CreateMessageMutation> {
    const statement = `mutation CreateMessage($input: CreateMessageInput!, $condition: ModelMessageConditionInput) {
        createMessage(input: $input, condition: $condition) {
          __typename
          id
          chatroomId
          type
          body
          sender
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMessageMutation>response.data.createMessage;
  }
  async UpdateMessage(
    input: UpdateMessageInput,
    condition?: ModelMessageConditionInput
  ): Promise<UpdateMessageMutation> {
    const statement = `mutation UpdateMessage($input: UpdateMessageInput!, $condition: ModelMessageConditionInput) {
        updateMessage(input: $input, condition: $condition) {
          __typename
          id
          chatroomId
          type
          body
          sender
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMessageMutation>response.data.updateMessage;
  }
  async DeleteMessage(
    input: DeleteMessageInput,
    condition?: ModelMessageConditionInput
  ): Promise<DeleteMessageMutation> {
    const statement = `mutation DeleteMessage($input: DeleteMessageInput!, $condition: ModelMessageConditionInput) {
        deleteMessage(input: $input, condition: $condition) {
          __typename
          id
          chatroomId
          type
          body
          sender
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMessageMutation>response.data.deleteMessage;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          username
          email
          askBootlegActivated
          birthday
          gender
          totalSessions
          disabled
          redditPreference
          mbti
          handOrientation
          appThemeMode
          appVersion
          ethnicity
          feedLikes
          location
          firstName
          lastName
          relationship
          employment
          profession
          chatroomId
          createdAt
          updatedAt
          pushNotification
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          username
          email
          askBootlegActivated
          birthday
          gender
          totalSessions
          disabled
          redditPreference
          mbti
          handOrientation
          appThemeMode
          appVersion
          ethnicity
          feedLikes
          location
          firstName
          lastName
          relationship
          employment
          profession
          chatroomId
          createdAt
          updatedAt
          pushNotification
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          username
          email
          askBootlegActivated
          birthday
          gender
          totalSessions
          disabled
          redditPreference
          mbti
          handOrientation
          appThemeMode
          appVersion
          ethnicity
          feedLikes
          location
          firstName
          lastName
          relationship
          employment
          profession
          chatroomId
          createdAt
          updatedAt
          pushNotification
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateDailypoll(
    input: CreateDailypollInput,
    condition?: ModelDailypollConditionInput
  ): Promise<CreateDailypollMutation> {
    const statement = `mutation CreateDailypoll($input: CreateDailypollInput!, $condition: ModelDailypollConditionInput) {
        createDailypoll(input: $input, condition: $condition) {
          __typename
          id
          question
          answer1
          answer2
          happyVote
          frustratedVote
          totalVote
          answer1Vote
          answer1Percent
          answer1PercentChart
          answer2Vote
          answer2Percent
          createdAt
          updatedAt
          ENTPAnswer1Vote
          ENTPAnswer2Vote
          ENTJAnswer1Vote
          ENTJAnswer2Vote
          INTPAnswer1Vote
          INTPAnswer2Vote
          INTJAnswer1Vote
          INTJAnswer2Vote
          ESTPAnswer1Vote
          ESTPAnswer2Vote
          ESTJAnswer1Vote
          ESTJAnswer2Vote
          ISTPAnswer1Vote
          ISTPAnswer2Vote
          ISTJAnswer1Vote
          ISTJAnswer2Vote
          ENFPAnswer1Vote
          ENFPAnswer2Vote
          ENFJAnswer1Vote
          ENFJAnswer2Vote
          INFPAnswer1Vote
          INFPAnswer2Vote
          INFJAnswer1Vote
          INFJAnswer2Vote
          ESFPAnswer1Vote
          ESFPAnswer2Vote
          ESFJAnswer1Vote
          ESFJAnswer2Vote
          ISFPAnswer1Vote
          ISFPAnswer2Vote
          ISFJAnswer1Vote
          ISFJAnswer2Vote
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDailypollMutation>response.data.createDailypoll;
  }
  async UpdateDailypoll(
    input: UpdateDailypollInput,
    condition?: ModelDailypollConditionInput
  ): Promise<UpdateDailypollMutation> {
    const statement = `mutation UpdateDailypoll($input: UpdateDailypollInput!, $condition: ModelDailypollConditionInput) {
        updateDailypoll(input: $input, condition: $condition) {
          __typename
          id
          question
          answer1
          answer2
          happyVote
          frustratedVote
          totalVote
          answer1Vote
          answer1Percent
          answer1PercentChart
          answer2Vote
          answer2Percent
          createdAt
          updatedAt
          ENTPAnswer1Vote
          ENTPAnswer2Vote
          ENTJAnswer1Vote
          ENTJAnswer2Vote
          INTPAnswer1Vote
          INTPAnswer2Vote
          INTJAnswer1Vote
          INTJAnswer2Vote
          ESTPAnswer1Vote
          ESTPAnswer2Vote
          ESTJAnswer1Vote
          ESTJAnswer2Vote
          ISTPAnswer1Vote
          ISTPAnswer2Vote
          ISTJAnswer1Vote
          ISTJAnswer2Vote
          ENFPAnswer1Vote
          ENFPAnswer2Vote
          ENFJAnswer1Vote
          ENFJAnswer2Vote
          INFPAnswer1Vote
          INFPAnswer2Vote
          INFJAnswer1Vote
          INFJAnswer2Vote
          ESFPAnswer1Vote
          ESFPAnswer2Vote
          ESFJAnswer1Vote
          ESFJAnswer2Vote
          ISFPAnswer1Vote
          ISFPAnswer2Vote
          ISFJAnswer1Vote
          ISFJAnswer2Vote
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDailypollMutation>response.data.updateDailypoll;
  }
  async DeleteDailypoll(
    input: DeleteDailypollInput,
    condition?: ModelDailypollConditionInput
  ): Promise<DeleteDailypollMutation> {
    const statement = `mutation DeleteDailypoll($input: DeleteDailypollInput!, $condition: ModelDailypollConditionInput) {
        deleteDailypoll(input: $input, condition: $condition) {
          __typename
          id
          question
          answer1
          answer2
          happyVote
          frustratedVote
          totalVote
          answer1Vote
          answer1Percent
          answer1PercentChart
          answer2Vote
          answer2Percent
          createdAt
          updatedAt
          ENTPAnswer1Vote
          ENTPAnswer2Vote
          ENTJAnswer1Vote
          ENTJAnswer2Vote
          INTPAnswer1Vote
          INTPAnswer2Vote
          INTJAnswer1Vote
          INTJAnswer2Vote
          ESTPAnswer1Vote
          ESTPAnswer2Vote
          ESTJAnswer1Vote
          ESTJAnswer2Vote
          ISTPAnswer1Vote
          ISTPAnswer2Vote
          ISTJAnswer1Vote
          ISTJAnswer2Vote
          ENFPAnswer1Vote
          ENFPAnswer2Vote
          ENFJAnswer1Vote
          ENFJAnswer2Vote
          INFPAnswer1Vote
          INFPAnswer2Vote
          INFJAnswer1Vote
          INFJAnswer2Vote
          ESFPAnswer1Vote
          ESFPAnswer2Vote
          ESFJAnswer1Vote
          ESFJAnswer2Vote
          ISFPAnswer1Vote
          ISFPAnswer2Vote
          ISFJAnswer1Vote
          ISFJAnswer2Vote
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDailypollMutation>response.data.deleteDailypoll;
  }
  async GetTodo(id: string): Promise<GetTodoQuery> {
    const statement = `query GetTodo($id: ID!) {
        getTodo(id: $id) {
          __typename
          id
          name
          description
          forceUpdateUseless
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTodoQuery>response.data.getTodo;
  }
  async ListTodos(
    filter?: ModelTodoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTodosQuery> {
    const statement = `query ListTodos($filter: ModelTodoFilterInput, $limit: Int, $nextToken: String) {
        listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            description
            forceUpdateUseless
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTodosQuery>response.data.listTodos;
  }
  async GetChatroom(
    flag: string,
    createdAt: string
  ): Promise<GetChatroomQuery> {
    const statement = `query GetChatroom($flag: String!, $createdAt: AWSDateTime!) {
        getChatroom(flag: $flag, createdAt: $createdAt) {
          __typename
          flag
          size
          createdAt
          updatedAt
          members
        }
      }`;
    const gqlAPIServiceArguments: any = {
      flag,
      createdAt
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetChatroomQuery>response.data.getChatroom;
  }
  async ListChatrooms(
    flag?: string,
    createdAt?: ModelStringKeyConditionInput,
    filter?: ModelChatroomFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListChatroomsQuery> {
    const statement = `query ListChatrooms($flag: String, $createdAt: ModelStringKeyConditionInput, $filter: ModelChatroomFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listChatrooms(flag: $flag, createdAt: $createdAt, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
          __typename
          items {
            __typename
            flag
            size
            createdAt
            updatedAt
            members
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (flag) {
      gqlAPIServiceArguments.flag = flag;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChatroomsQuery>response.data.listChatrooms;
  }
  async GetMessage(
    chatroomId: string,
    createdAt: string
  ): Promise<GetMessageQuery> {
    const statement = `query GetMessage($chatroomId: String!, $createdAt: AWSDateTime!) {
        getMessage(chatroomId: $chatroomId, createdAt: $createdAt) {
          __typename
          id
          chatroomId
          type
          body
          sender
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      chatroomId,
      createdAt
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMessageQuery>response.data.getMessage;
  }
  async ListMessages(
    chatroomId?: string,
    createdAt?: ModelStringKeyConditionInput,
    filter?: ModelMessageFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMessagesQuery> {
    const statement = `query ListMessages($chatroomId: String, $createdAt: ModelStringKeyConditionInput, $filter: ModelMessageFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMessages(chatroomId: $chatroomId, createdAt: $createdAt, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
          __typename
          items {
            __typename
            id
            chatroomId
            type
            body
            sender
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (chatroomId) {
      gqlAPIServiceArguments.chatroomId = chatroomId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMessagesQuery>response.data.listMessages;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          username
          email
          askBootlegActivated
          birthday
          gender
          totalSessions
          disabled
          redditPreference
          mbti
          handOrientation
          appThemeMode
          appVersion
          ethnicity
          feedLikes
          location
          firstName
          lastName
          relationship
          employment
          profession
          chatroomId
          createdAt
          updatedAt
          pushNotification
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            username
            email
            askBootlegActivated
            birthday
            gender
            totalSessions
            disabled
            redditPreference
            mbti
            handOrientation
            appThemeMode
            appVersion
            ethnicity
            feedLikes
            location
            firstName
            lastName
            relationship
            employment
            profession
            chatroomId
            createdAt
            updatedAt
            pushNotification
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetDailypoll(id: string): Promise<GetDailypollQuery> {
    const statement = `query GetDailypoll($id: ID!) {
        getDailypoll(id: $id) {
          __typename
          id
          question
          answer1
          answer2
          happyVote
          frustratedVote
          totalVote
          answer1Vote
          answer1Percent
          answer1PercentChart
          answer2Vote
          answer2Percent
          createdAt
          updatedAt
          ENTPAnswer1Vote
          ENTPAnswer2Vote
          ENTJAnswer1Vote
          ENTJAnswer2Vote
          INTPAnswer1Vote
          INTPAnswer2Vote
          INTJAnswer1Vote
          INTJAnswer2Vote
          ESTPAnswer1Vote
          ESTPAnswer2Vote
          ESTJAnswer1Vote
          ESTJAnswer2Vote
          ISTPAnswer1Vote
          ISTPAnswer2Vote
          ISTJAnswer1Vote
          ISTJAnswer2Vote
          ENFPAnswer1Vote
          ENFPAnswer2Vote
          ENFJAnswer1Vote
          ENFJAnswer2Vote
          INFPAnswer1Vote
          INFPAnswer2Vote
          INFJAnswer1Vote
          INFJAnswer2Vote
          ESFPAnswer1Vote
          ESFPAnswer2Vote
          ESFJAnswer1Vote
          ESFJAnswer2Vote
          ISFPAnswer1Vote
          ISFPAnswer2Vote
          ISFJAnswer1Vote
          ISFJAnswer2Vote
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDailypollQuery>response.data.getDailypoll;
  }
  async ListDailypolls(
    filter?: ModelDailypollFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDailypollsQuery> {
    const statement = `query ListDailypolls($filter: ModelDailypollFilterInput, $limit: Int, $nextToken: String) {
        listDailypolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            question
            answer1
            answer2
            happyVote
            frustratedVote
            totalVote
            answer1Vote
            answer1Percent
            answer1PercentChart
            answer2Vote
            answer2Percent
            createdAt
            updatedAt
            ENTPAnswer1Vote
            ENTPAnswer2Vote
            ENTJAnswer1Vote
            ENTJAnswer2Vote
            INTPAnswer1Vote
            INTPAnswer2Vote
            INTJAnswer1Vote
            INTJAnswer2Vote
            ESTPAnswer1Vote
            ESTPAnswer2Vote
            ESTJAnswer1Vote
            ESTJAnswer2Vote
            ISTPAnswer1Vote
            ISTPAnswer2Vote
            ISTJAnswer1Vote
            ISTJAnswer2Vote
            ENFPAnswer1Vote
            ENFPAnswer2Vote
            ENFJAnswer1Vote
            ENFJAnswer2Vote
            INFPAnswer1Vote
            INFPAnswer2Vote
            INFJAnswer1Vote
            INFJAnswer2Vote
            ESFPAnswer1Vote
            ESFPAnswer2Vote
            ESFJAnswer1Vote
            ESFJAnswer2Vote
            ISFPAnswer1Vote
            ISFPAnswer2Vote
            ISFJAnswer1Vote
            ISFJAnswer2Vote
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDailypollsQuery>response.data.listDailypolls;
  }
  OnCreateTodoListener: Observable<
    SubscriptionResponse<OnCreateTodoSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTodo {
        onCreateTodo {
          __typename
          id
          name
          description
          forceUpdateUseless
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateTodoSubscription>>;

  OnUpdateTodoListener: Observable<
    SubscriptionResponse<OnUpdateTodoSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTodo {
        onUpdateTodo {
          __typename
          id
          name
          description
          forceUpdateUseless
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateTodoSubscription>>;

  OnDeleteTodoListener: Observable<
    SubscriptionResponse<OnDeleteTodoSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTodo {
        onDeleteTodo {
          __typename
          id
          name
          description
          forceUpdateUseless
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteTodoSubscription>>;

  OnCreateChatroomListener: Observable<
    SubscriptionResponse<OnCreateChatroomSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateChatroom {
        onCreateChatroom {
          __typename
          flag
          size
          createdAt
          updatedAt
          members
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateChatroomSubscription>>;

  OnUpdateChatroomListener: Observable<
    SubscriptionResponse<OnUpdateChatroomSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateChatroom {
        onUpdateChatroom {
          __typename
          flag
          size
          createdAt
          updatedAt
          members
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateChatroomSubscription>>;

  OnDeleteChatroomListener: Observable<
    SubscriptionResponse<OnDeleteChatroomSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteChatroom {
        onDeleteChatroom {
          __typename
          flag
          size
          createdAt
          updatedAt
          members
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteChatroomSubscription>>;

  OnCreateMessageListener: Observable<
    SubscriptionResponse<OnCreateMessageSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMessage {
        onCreateMessage {
          __typename
          id
          chatroomId
          type
          body
          sender
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateMessageSubscription>>;

  OnUpdateMessageListener: Observable<
    SubscriptionResponse<OnUpdateMessageSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMessage {
        onUpdateMessage {
          __typename
          id
          chatroomId
          type
          body
          sender
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateMessageSubscription>>;

  OnDeleteMessageListener: Observable<
    SubscriptionResponse<OnDeleteMessageSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMessage {
        onDeleteMessage {
          __typename
          id
          chatroomId
          type
          body
          sender
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteMessageSubscription>>;

  OnCreateUserListener: Observable<
    SubscriptionResponse<OnCreateUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser {
        onCreateUser {
          __typename
          id
          username
          email
          askBootlegActivated
          birthday
          gender
          totalSessions
          disabled
          redditPreference
          mbti
          handOrientation
          appThemeMode
          appVersion
          ethnicity
          feedLikes
          location
          firstName
          lastName
          relationship
          employment
          profession
          chatroomId
          createdAt
          updatedAt
          pushNotification
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateUserSubscription>>;

  OnUpdateUserListener: Observable<
    SubscriptionResponse<OnUpdateUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          id
          username
          email
          askBootlegActivated
          birthday
          gender
          totalSessions
          disabled
          redditPreference
          mbti
          handOrientation
          appThemeMode
          appVersion
          ethnicity
          feedLikes
          location
          firstName
          lastName
          relationship
          employment
          profession
          chatroomId
          createdAt
          updatedAt
          pushNotification
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateUserSubscription>>;

  OnDeleteUserListener: Observable<
    SubscriptionResponse<OnDeleteUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser {
        onDeleteUser {
          __typename
          id
          username
          email
          askBootlegActivated
          birthday
          gender
          totalSessions
          disabled
          redditPreference
          mbti
          handOrientation
          appThemeMode
          appVersion
          ethnicity
          feedLikes
          location
          firstName
          lastName
          relationship
          employment
          profession
          chatroomId
          createdAt
          updatedAt
          pushNotification
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteUserSubscription>>;

  OnCreateDailypollListener: Observable<
    SubscriptionResponse<OnCreateDailypollSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDailypoll {
        onCreateDailypoll {
          __typename
          id
          question
          answer1
          answer2
          happyVote
          frustratedVote
          totalVote
          answer1Vote
          answer1Percent
          answer1PercentChart
          answer2Vote
          answer2Percent
          createdAt
          updatedAt
          ENTPAnswer1Vote
          ENTPAnswer2Vote
          ENTJAnswer1Vote
          ENTJAnswer2Vote
          INTPAnswer1Vote
          INTPAnswer2Vote
          INTJAnswer1Vote
          INTJAnswer2Vote
          ESTPAnswer1Vote
          ESTPAnswer2Vote
          ESTJAnswer1Vote
          ESTJAnswer2Vote
          ISTPAnswer1Vote
          ISTPAnswer2Vote
          ISTJAnswer1Vote
          ISTJAnswer2Vote
          ENFPAnswer1Vote
          ENFPAnswer2Vote
          ENFJAnswer1Vote
          ENFJAnswer2Vote
          INFPAnswer1Vote
          INFPAnswer2Vote
          INFJAnswer1Vote
          INFJAnswer2Vote
          ESFPAnswer1Vote
          ESFPAnswer2Vote
          ESFJAnswer1Vote
          ESFJAnswer2Vote
          ISFPAnswer1Vote
          ISFPAnswer2Vote
          ISFJAnswer1Vote
          ISFJAnswer2Vote
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateDailypollSubscription>>;

  OnUpdateDailypollListener: Observable<
    SubscriptionResponse<OnUpdateDailypollSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDailypoll {
        onUpdateDailypoll {
          __typename
          id
          question
          answer1
          answer2
          happyVote
          frustratedVote
          totalVote
          answer1Vote
          answer1Percent
          answer1PercentChart
          answer2Vote
          answer2Percent
          createdAt
          updatedAt
          ENTPAnswer1Vote
          ENTPAnswer2Vote
          ENTJAnswer1Vote
          ENTJAnswer2Vote
          INTPAnswer1Vote
          INTPAnswer2Vote
          INTJAnswer1Vote
          INTJAnswer2Vote
          ESTPAnswer1Vote
          ESTPAnswer2Vote
          ESTJAnswer1Vote
          ESTJAnswer2Vote
          ISTPAnswer1Vote
          ISTPAnswer2Vote
          ISTJAnswer1Vote
          ISTJAnswer2Vote
          ENFPAnswer1Vote
          ENFPAnswer2Vote
          ENFJAnswer1Vote
          ENFJAnswer2Vote
          INFPAnswer1Vote
          INFPAnswer2Vote
          INFJAnswer1Vote
          INFJAnswer2Vote
          ESFPAnswer1Vote
          ESFPAnswer2Vote
          ESFJAnswer1Vote
          ESFJAnswer2Vote
          ISFPAnswer1Vote
          ISFPAnswer2Vote
          ISFJAnswer1Vote
          ISFJAnswer2Vote
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateDailypollSubscription>>;

  OnDeleteDailypollListener: Observable<
    SubscriptionResponse<OnDeleteDailypollSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDailypoll {
        onDeleteDailypoll {
          __typename
          id
          question
          answer1
          answer2
          happyVote
          frustratedVote
          totalVote
          answer1Vote
          answer1Percent
          answer1PercentChart
          answer2Vote
          answer2Percent
          createdAt
          updatedAt
          ENTPAnswer1Vote
          ENTPAnswer2Vote
          ENTJAnswer1Vote
          ENTJAnswer2Vote
          INTPAnswer1Vote
          INTPAnswer2Vote
          INTJAnswer1Vote
          INTJAnswer2Vote
          ESTPAnswer1Vote
          ESTPAnswer2Vote
          ESTJAnswer1Vote
          ESTJAnswer2Vote
          ISTPAnswer1Vote
          ISTPAnswer2Vote
          ISTJAnswer1Vote
          ISTJAnswer2Vote
          ENFPAnswer1Vote
          ENFPAnswer2Vote
          ENFJAnswer1Vote
          ENFJAnswer2Vote
          INFPAnswer1Vote
          INFPAnswer2Vote
          INFJAnswer1Vote
          INFJAnswer2Vote
          ESFPAnswer1Vote
          ESFPAnswer2Vote
          ESFJAnswer1Vote
          ESFJAnswer2Vote
          ISFPAnswer1Vote
          ISFPAnswer2Vote
          ISFJAnswer1Vote
          ISFJAnswer2Vote
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteDailypollSubscription>>;
}
